import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

// Web Components
import * as action from "../redux/actions";
import Aside from "../components/aside/aside";
import CategoriesTable from "../components/categories/category-table";
import Paginator from "../components/paginator/paginator";

class Categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };

    this.props.dispatch(action.getCategories());
  }

  setpage(page) {
    this.setState({ page: page });
  }

  refresh() {
    this.props.dispatch(action.getCategories());
  }

  render() {
    return (
      <div className="workspace">
        <Aside />
        <div className="control-content justify-center">
          <div className="container column">
            <div className="white-space-32" />
            <div className="align-center">
              <div>
                <h3 className="color-black">CATEGORIAS</h3>
              </div>
              <div className="white-space-16" />
            </div>
            <div className="white-space-16" />
            <div className="row">
              <div className="left justify-start">
                <button
                  className="btn btn-green" onClick={this.refresh.bind(this)} >
                  <i className="fas fa-sync" />
                  &nbsp; Recargar
                </button>
              </div>
              <div className="right justify-end">
                <Link  to="categorias/agregar" className="btn btn-aqua" id="open-modal-add-user">
                  <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR CATEGORIA
                </Link>
              </div>
            </div>
            <div className="white-space-16" />
            <CategoriesTable page={this.state.page} />
            <div className="white-space-32" />
            <Paginator
              setpage={this.setpage.bind(this)}
              items={Math.ceil(this.props.categories.length / 10)}
            />
            <div className="white-space-32" />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.categories
  };
}

export default connect(mapStateToProps)(Categorias);
