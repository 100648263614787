import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider, addLocaleData } from "react-intl";

// Web Components
import Header from "./components/header/header";
import store from "./redux/store";

// Web Pages
import Login from "./pages/login";
import Tablero from "./pages/dashboard";
import Pedidos from "./pages/pedidos";
import Clientes from "./pages/clientes";
import Usuarios from "./pages/usuarios";
import Productos from "./pages/productos";
import Categorias from "./pages/categorias";
import DetalleProducto from "./pages/detalle-producto";
import DetalleCategoria from "./pages/detalle-categoria";
import Template from "./pages/template";
import Banner from './pages/banner';
import ProductoBanner from './pages/detalle-banner';
import Promociones from './pages/promociones';
import Reportes from './pages/reportes';

//locales
import es from 'react-intl/locale-data/es';
addLocaleData([...es]);

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (store.getState().auth && window.location.pathname === "/") {
      window.location = "/tablero";
    }
    if (!store.getState().auth && window.location.pathname === "/") {
      window.location = "/login";
    }
  }
  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          store.getState().auth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
    return (
      <IntlProvider locale="es">
        <Provider store={store}>
          <BrowserRouter basename="/">
            <div className="admin flex">
              <div className="column">
                <Header />
                <Switch>
                  <Route path="/login" exact component={Login} />
                  <PrivateRoute path="/tablero" exact component={Tablero} />
                  <PrivateRoute path="/pedidos" exact component={Pedidos} />
                  <PrivateRoute path="/clientes" exact component={Clientes} />
                  <PrivateRoute path="/usuarios" exact component={Usuarios} />
                  <PrivateRoute path="/productos" exact component={Productos} />
                  <PrivateRoute path="/banner" exact component={Banner} />
                  <PrivateRoute path="/promociones" exact component={Promociones} />
                  <PrivateRoute path="/reportes" exact component={Reportes} />
                  <PrivateRoute
                    path="/categorias"
                    exact
                    component={Categorias}
                  />
                  <PrivateRoute path="/template" exact component={Template} />
                  <PrivateRoute
                    path="/productos/agregar"
                    exact
                    component={DetalleProducto}
                  />
                  <PrivateRoute
                    path="/productos/modificar/:id"
                    exact
                    component={DetalleProducto}
                  />
                  <PrivateRoute
                    path="/categorias/modificar/:id"
                    exact
                    component={DetalleCategoria}
                  />
                  <PrivateRoute
                    path="/categorias/agregar"
                    exact
                    component={DetalleCategoria}
                  />
                  <PrivateRoute
                    path="/productos/banner/agregar"
                    exact
                    component={ProductoBanner}
                  />
                  <PrivateRoute
                    path="/productos/banner/modificar/:id"
                    exact
                    component={ProductoBanner}
                  />
                </Switch>
              </div>
            </div>
          </BrowserRouter>
        </Provider>
      </IntlProvider>
    );
  }
}

export default Routes;
