import React, { Component } from "react";
import { connect } from "react-redux";

// Web Component
import ProductsRow from "./product-row";

class ProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numitems: 10
    };
  }
  render() {
    let products = [];
    if (this.props.products) {
      products = this.props.products.slice((this.props.page - 1 ) * 10 , this.props.page * 10);
    }
    return (
      <div className="products-table">
        <div className="cart-container justify-center">
          <div className="column">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th> IMAGEN </th>
                    <th> SKU </th>
                    <th className="th-name"> PRODUCTO </th>
                    <th> VISIBLE </th>
                    <th> RELEVANTE </th>
                    <th> PRECIO </th>
                    <th> PESO(Kg) </th>
                    <th> MODIFICAR </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fas fa-spinner fa-spin"></i>
                      </td>
                    </tr>
                  ) : null}
                  {products.map((product, index) => (
                    <ProductsRow key={index} {...product} />
                  ))}
                  {this.props.products_error ? (
                    <tr>
                      <td colSpan="7">{this.props.products_error}</td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    products: state.products,
    products_error: state.products_error,
    loading: state.products_loading
  };
}

export default connect(mapStateToProps)(ProductsTable);
