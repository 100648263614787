import React, { Component } from 'react';
import Aside from "../components/aside/aside";
import { connect } from "react-redux";
import * as action from "../redux/actions";
import { Request } from '../api/apiservice';
import ModalBannerVP from '../components/modals/banner-vp';
import { SliderPicker, CompactPicker, SwatchesPicker } from 'react-color';

const req = new Request();

class DetailProductBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagen: '',
            locationStatus: "none",
            name: '',
            description: '',
            name_en: '',
            description_en: '',
            line: 0,
            category: 0,
            status: 0,
            image: '',
            color: 'white',
            first: true
        };
    }

    async load() {
        if (this.props.match) {
            const params = this.props.match.params;
            console.log(this.props.match);
            if (params.id) {
                const data = {
                    idBProducto: params.id
				};
				//Quitar este
				//this.props.dispatch(action.getProductoBanner(data));
				//Usar este
                const res = await req.post('/admin/products/banner/id', data);
                if (res) {
                    console.log(res);
                    let name = [], description = [];
                    if (res.data) {
                        if (res.data.nombre) {
                            name = JSON.parse(res.data.nombre);
                        }
                        if (res.data.descripcion) {
                            description = JSON.parse(res.data.descripcion);
                        }
                        console.log(res.data.linea);
                        this.setState({ name: name['es'], description: description['es'], name_en: name['en'], description_en: description['en'], image: res.data.imagen, status: res.data.status, line: res.data.linea, category: res.data.categoria, color: res.data.colorText });   

                        if (res.data.linea) {
                            this.props.dispatch(
                                action.getCategoriesByLine(res.data.linea, 'es')
                            );
                        } 
                    }
                }

                this.setState({ locationStatus: "modificar" });
            } else {
                this.setState({ locationStatus: "agregar", nuevo: true });
            }
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ banner_loading: true });
        const form = e.target;
        const name = form.product_name.value;
        const description = form.product_description.value;
        const name_en = form.product_name_en.value;
        const description_en = form.product_description_en.value;
        const line = form.line.value;
        const category = form.category.value;

        if (this.state.locationStatus === 'modificar') {
            let status = form.statusPBanner.checked;
            if (status) {
                status = 1;
            } else {
                status = 0;
            }
            const id = this.props.match.params.id;
            const data = { id: id, name: { es: name, en: name_en }, description: { es: description, en: description_en }, image: this.state.imagen ? this.state.imagen : this.state.image, line: line, category: category, status: status, color: this.state.color };
            //this.props.dispatch(action.updateProductBanner(data));
            const res = await req.post('/admin/products/actualizar/banner', data);
            if (res) {
                this.setState({ banner_loading: false });
                if (res.error) {
                    console.log(res.error);
                } else {
                    alert(res.message);
                    window.location.reload();
                }
            }
        } else {
            if (this.state.imagen === '') {
                alert('Es necesario agregar una imagen.');
                this.setState({ banner_loading: false });
            } else {
                const data = { name: { es: name, en: name_en }, description: { es: description, en: description_en }, image: this.state.imagen, line: line, category: category, color: this.state.color};
                console.log(data);
                //this.props.dispatch(action.addProductBanner(data));
                const res = await req.post('/admin/products/agregar/banner', data);
                if (res) {
                    this.setState({ banner_loading: false });
                    if (res.error) {
                        console.log(res.error);
                    } else {
                        alert(res.message);
                        window.location.reload();
                    }
                }
            }
        }
    }

    handleChange(event) {
        const src = event.target;
        let fr = new FileReader();
        fr.onload = e => {
            console.log(e);
            document.getElementById("product-image").src = e.target.result;
            this.setState({ imagen: e.target.result, first: false });
        };

        if (src.files.length > 0) {
            fr.readAsDataURL(src.files[0]);
        }
    }

    hanldeChangeLine(event) {
        this.setState({ line: event.target.value });
        this.props.dispatch(
            action.getCategoriesByLine(event.target.value, 'es')
        );
    }

    openModalBanner() {
        if (this.state.locationStatus === 'modificar') {
            document.getElementById("modal-banner").classList.remove("hidden");
        } else {
            if (this.state.imagen !== '') {
                document.getElementById("modal-banner").classList.remove("hidden");
            }
        }
    }

    onChangeColor(color) {
        console.log(color);
        this.setState({ color: color.hex });
    }

    render() {
        let lines = [], categories = [], product = [];
        let nombre = '', descripcion = '';
        if (this.props.lines) {
            lines = this.props.lines;
            console.log(lines);
        }

        if (this.props.categories) {
            categories = this.props.categories;
            console.log(categories);
        }

        return(
            <div className="workspace">
                <Aside />
                <div className="product-container control-content column justify-center align-center">
                    <div className="container column align-center">
                        <div className="white-space-32" />
                        <div className="align-center">
                            <div>
                                <h3 className="color-black">
                                    {this.state.locationStatus === "agregar" ?
                                    'AGREGAR PRODUCTO' : 'MODIFICAR PRODUCTO'}
                                </h3>
                            </div>
                        </div>
                        <div className="white-space-32"></div>
                        <div className="product-b column align-center">
                            <div className="white-space-48"></div>
                            <div className="product-images column">
                                <div className="principal-img" onClick={() => document.getElementById("upload_image").click()}>
                                    <img src={this.state.image || "https://via.placeholder.com/400?text=Click+para.subir+imagen"}
                                        alt="Click para cambiar imagen"
                                        name="product-image"
                                        id="product-image" />
                                    <div className="rd-parallax-gallery">
                                        <div className="top-text justify-center align-center">
                                            <i className="fas fa-upload"></i>
                                        </div>
                                    </div>
                                    <input type="file" name="upload_image" onChange={this.handleChange.bind(this)} id="upload_image" style={{ display: 'none' }} /*onChange={this.previewImage.bind(this)}*/ />
                                </div>
                            </div>
                            <form className="column container htmlForm-add-product" onSubmit={this.handleSubmit.bind(this)}>
                                <div className="white-space-16" />
                                    <label htmlFor="product_name">Nombre del producto(ESP)</label>
                                <div className="white-space-8" />
                                <input
                                    type="text"
                                    name="product_name"
                                    id="product_name"
                                    className="input input-text"
                                    value={this.state.name || ''}
                                    onChange={(event) => {
                                        this.setState({ name: event.target.value });
                                    }}
                                    required
                                />
                                <div className="white-space-16" />
                                    <label htmlFor="product_description">Descripción(ESP)</label>
                                <div className="white-space-8" />
                                <textarea
                                    name="product_description"
                                    id="product_description"
                                    rows="5"
                                    className="input textarea"
                                    maxLength="800"
                                    value={this.state.description || ''}
                                    onChange={(event) => {
                                        this.setState({ description: event.target.value });
                                    }}
                                />
                                <div className="white-space-24" />
                                <label htmlFor="product_name_ing">
                                    Nombre del producto(ING)
                                </label>
                                <div className="white-space-8" />
                                <input
                                    type="text"
                                    name="product_name_en"
                                    id="product_name_en"
                                    className="input input-text"
                                    value={this.state.name_en || ''}
                                    required
                                    onChange={(event) => {
                                        this.setState({ name_en: event.target.value });
                                    }}
                                />
                                <div className="white-space-16" />
                                <label htmlFor="product_description_ing">
                                    Descripción(ING)
                                </label>
                                <div className="white-space-8" />
                                <textarea
                                    name="product_description_en"
                                    id="product_description_en"
                                    rows="5"
                                    className="input textarea"
                                    maxLength="800"
                                    value={this.state.description_en || ''}
                                    onChange={(event) => {
                                        this.setState({ description_en: event.target.value });
                                    }}
                                />
                                <div className="white-space-16"></div>
                                <div className="row">
                                    <div className="column">
                                        <label htmlFor="product_description_ing">
                                            Línea de productos
                                        </label>
                                        <div className="white-space-8" />
                                        <select className="input-select" name="line" id="line" value={this.state.line} onChange={this.hanldeChangeLine.bind(this)}>
                                            {lines.map((line, index) => 
                                                <option value={line.idLinea} key={index}>{line.Linea}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="column">
                                        <label htmlFor="product_description_ing">
                                            Categoría
                                        </label>
                                        <div className="white-space-8" />
                                        <select className="input-select" name="category" id="category" value={this.state.category} onChange={(event) => this.setState({ category: event.target.value })}>
                                            {categories.map((cat, index) =>
                                                <option value={cat.idCategoria} key={index}>{cat.descripcion}</option>
                                            )}
                                        </select>
                                    </div> 
                                    {this.state.locationStatus === 'modificar' ?
                                    <div className="column align-center justify-center">
                                        <label htmlFor="product_description_ing">
                                            Estatus
                                        </label>
                                        <div className="white-space-8"></div>
                                            <input type="checkbox" checked={parseInt(this.state.status) === 1 ? true : false} onChange={(event) => this.setState({ status: event.target.checked === false ? 0 : 1 })} name="statusPBanner" id="statusPBanner"/>
                                        </div> : null}
                                </div>
                                <div className="white-space-24"></div>
                                <div className="color-text-banner column">
                                    <label htmlFor="product_description_ing">
                                        Color de texto
                                    </label>
                                    <div className="white-space-16"></div>
                                    <SwatchesPicker color={this.state.color} onChangeComplete={this.onChangeColor.bind(this)} />
                                </div>
                                <div className="white-space-24"></div>
                                <div>
                                    <div className="vp">
                                        <button type="button" className="btn btn-green" onClick={this.openModalBanner.bind(this)}>
                                            <i class="far fa-eye"></i>&nbsp; VISTA PREVIA
                                        </button>
                                    </div>
                                    <div className="add justify-end">
                                        {this.props.locationStatus === 'modificar' ?
                                            this.state.banner_loading ?
                                                <button type="button" className="btn btn-green">
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-green">
                                                    <i class="fas fa-check"></i>&nbsp; ACEPTAR 
                                                </button>
                                            :
                                            this.state.banner_loading ?
                                                <button type="button" className="btn btn-green">
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                </button>
                                                :
                                                <button type="submit" className="btn btn-green">
                                                    <i class="fas fa-check"></i>&nbsp; ACEPTAR
                                                </button>
                                        }
                                    </div>
                                </div>
                                <div className="white-space-48"></div>
                            </form>
                        </div>
                        <div className="white-space-32"></div>
                    </div>
                    <ModalBannerVP image={this.state.locationStatus === 'modificar' ? this.state.first === true ? this.state.image : this.state.imagen : this.state.imagen} description={this.state.description} color={this.state.color} />
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.load();

        this.props.dispatch(action.getLines());
        //this.props.dispatch(action.getCategories());
        this.props.dispatch(
            action.getCategoriesByLine(1, 'es')
        );
    }
}

function mapStateToProps(state) {
  return {
      categories: state.categories,
      lines: state.lines,
	  product_detail: state.product_detail,
      update_loading_banner: state.update_loading_banner,
      add_banner_loading: state.add_banner_loading
  };
}

export default connect(mapStateToProps)(DetailProductBanner);