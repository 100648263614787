import React, { Component } from 'react';

class ModalBannerEdit extends Component {
    render() {
        return(
            <div className="flex modal justify-center hidden" id="modal-banner-edit">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            EDITAR ESTATUS
                        </h2>
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-32"></div>
                        <form className="justify-center align-center">
                            <label>ESTATUS</label>&nbsp;
                            <input type="checkbox" name="status" id="status"/>
                        </form>
                        <div className="white-space-32"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalBannerEdit;