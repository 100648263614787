import React, { Component } from 'react';
import { connect } from "react-redux";
// Web Components
import Aside from "../components/aside/aside";
import DetailProductBanner from './detalle-banner';
import * as action from "../redux/actions";
import BannerTable from '../components/products/banner-table';
import { Link } from 'react-router-dom';
import ModalBannerEdit from '../components/modals/banner-edit';
import Paginator from '../components/paginator/paginator';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
    }

    componentDidMount() {
        this.getProductosBanner();
    }

    getProductosBanner() {
        this.props.dispatch(action.getProductsBanner());
    }

    setpage(page) {
        this.setState({ page: page });
    }

    render() {
		let products_banner = [];
		if(Array.isArray(this.props.products_banner)) {
			products_banner = this.props.products_banner
		}
        return(
            <div className="workspace">
                <Aside />
                <div className="control-content justify-center">
                    <div className="container column">
                        <div className="white-space-32" />
                        <div className="align-center">
                            <div>
                                <h3 className="color-black">
                                    PRODUCTOS EN BANNER
                                </h3>
                            </div>
                        </div>
                        <div className="white-space-24" />
                        <div className="">
                            <div className="justify-start">
                                <button className="btn btn-aqua" onClick={this.getProductosBanner.bind(this)}>
                                    <i class="fas fa-sync-alt"></i>&nbsp; ACTUALIZAR
                                </button>
                            </div>
                            <div className="justify-end">
                                <Link to="productos/banner/agregar" className="btn btn-aqua">
                                    <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR PRODUCTO
                                </Link>
                            </div>
                        </div>
                        <div className="white-space-24"></div>
                        {/*<DetailProductBanner saveProductBanner={this.saveProductBanner.bind(this)}/>*/}
                        {this.props.products_banner_loading ? 
                            <div className="justify-center">
                                <div className="white-space-24"></div>
                                <i className="fas fa-spinner fa-spin"></i>
                                <div className="white-space-24"></div>
                            </div> :
                            <React.Fragment>
                                <BannerTable page={this.state.page} productsBanner={products_banner} />
                                <div className="white-space-32"></div>
                                <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(products_banner.length / 10)} /> 
                            </React.Fragment>
                        }
                        <div className="white-space-32" />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
  return {
	  products_banner: state.products_banner,
      products_banner_loading: state.products_banner_loading
  };
}

export default connect(mapStateToProps)(Banner);