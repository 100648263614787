import React, { Component } from 'react';
import { connect } from 'react-redux';

// Web Components
import Aside from '../components/aside/aside';
import UsersTable from '../components/users/users-table';
import UserModal from '../components/modals/user';
import Paginator from '../components/paginator/paginator';
import * as action from '../redux/actions';

class Usuarios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        }
        this.props.dispatch(action.getAllUsers());
    }

    setpage(page) {
        this.setState({ page: page })
    }
    
    render() {
        return(
            <div className="workspace">
                <Aside />
                <div className="control-content justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <div className="align-center">
                            <div>
                                <h3 className="color-black">
                                    TODOS LOS USUARIOS
                                </h3>
                            </div>
                            <div className="justify-end">
                                <button className="btn btn-aqua" id="open-modal-add-user">
                                    <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR USUARIO
                                </button>
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <UsersTable page={this.state.page} />
                        <div className="white-space-32"></div>
                        <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.props.users.length / 10)} /> 
                        <div className="white-space-32"></div>
                    </div>
                </div>
                <UserModal />
            </div>
        );
    }

    componentDidMount() {

        var modal = document.getElementById('modal-add-user');

        var btn = document.getElementById("open-modal-add-user");

        btn.onclick = function () {
            modal.classList.remove("hidden");
        }

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.classList.add("hidden");
            }
        }

        window.onkeydown = (event) => {
            if (event.keyCode === 27) {
                modal.classList.add("hidden");
            } else {
                return 0;
            }
        }

    }

}

function mapStateToProps(state) {
    return {
        users: state.users
    };
}

export default connect(mapStateToProps)(Usuarios);