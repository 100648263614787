import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer';
import apiservice from '../api/apiservice';

/*  
*   --- Function to get the storage states in the Local Storage. ---
*
*   1. serializedState is equals to object that contains all the local storage
*   2. Only if is the first time the serializedState is equal to "Null"
*   3. The serializedState is parsed on a JSON encode and return it
*/

export const loadState = function loadState() {

    try {

        let serializedState = localStorage.getItem('ELFOSADMIN');

        if (serializedState === null) {
            return undefined;
        }

        serializedState = JSON.parse(serializedState)
        return serializedState;

    } catch (error) {

        console.log('ERROR TYPE: STORE - ' + error);
        return undefined;

    }

};

// Store all state
const persistedState = loadState();

const store = createStore(reducer, persistedState, applyMiddleware(apiservice));

// Update the local storage

const saveState = function saveState(state) {

    try {

        let serializedState = JSON.stringify(state);
        localStorage.setItem('ELFOSADMIN', serializedState);
        localStorage.setItem('ELFOSADMIN_version', '1.0.0');

    } catch (error) {
        console.log('ERROR TYPE: SAVE STATE:  ' + error);
    }
};

//  Listen all Store Changes with the function subscribe
//  https://es.redux.js.org/docs/api/Store.html#subscribe

store.subscribe(() => {
    saveState(store.getState());
});

export default store;