import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";

// Web Components
import SummaryRow from "./summary-row";
import * as action from "../../redux/actions";

class SummaryOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };

    this.props.dispatch(
      action.getOrdersDash(
        dayjs()
          .add(-7, "day")
          .format("MM/DD/YYYY"),
        dayjs().format("MM/DD/YYYY"),
        this.response.bind(this)
      )
    );
  }
  response(response) {
    if (response.error) {
    } else {
      this.setState({ data: response.data });
    }
  }
  render() {
    return (
      <div className="column">
        <div className="white-space-32" />
        <h3 className="color-black">ÚLTIMOS PEDIDOS REALIZADOS</h3>
        <div className="white-space-16" />
        <div className="white-space-16" />
        <div className="summary-orders-table">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th> FOLIO </th>
                  <th> FECHA </th>
                  <th> IMPORTE </th>
                </tr>
              </thead>
              <tbody>
                {this.props.loading ? (
                  <tr>
                    <td colSpan="3">
                      <i className="fas fa-spinner fa-spin" />
                    </td>
                  </tr>
                ) : (
                  this.state.data.map((row, index) => <SummaryRow key={index} row={row} />)
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    loading: state.dashboard_orders_loading
  };
}
export default connect(mapStateToProps)(SummaryOrders);
