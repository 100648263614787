import React from 'react';

// Images
import Images from '../../img/index';

const DashBoardCards = function( props ) {

    return(
        <div className="admin-dashboard-cards gutters">
            <div className="card-products column" style={{ backgroundImage: `url(${Images['card-products']})` }}>
                <h4 className="text-center">
                    PRODUCTOS
                </h4>
                <div className="white-space-8"></div>
                <h3 className="text-center">
                    { props.loading? <i className="fas fa-spinner fa-spin"/> : props.products }
                </h3>
            </div>
            <div className="card-clients column" style={{ backgroundImage: `url(${Images['card-clients']})` }}>
                <h4 className="text-center">
                    CLIENTES
                </h4>
                <div className="white-space-8"></div>
                <h3 className="text-center">
                    { props.loading? <i className="fas fa-spinner fa-spin"/> : props.clients }
                </h3>
            </div>
            <div className="card-orders column" style={{ backgroundImage: `url(${Images['card-orders']})` }}>
                <h4 className="text-center">
                    ORDENES
                </h4>
                <div className="white-space-8"></div>
                <h3 className="text-center">
                    { props.loading? <i className="fas fa-spinner fa-spin"/> : props.orders }
                </h3>
            </div>
        </div>
    );

}

export default DashBoardCards;