import React, { Component } from 'react';
import moment from 'moment-timezone';

class PromotionRow extends Component {
    render() {
        let promocion = [];
        if (this.props.promotion) {
            promocion = this.props.promotion;
            console.log(promocion);
        }

        return(
            <tr className="table-row text-center">
                <td>
                    {promocion.id_codigopromocion}
                </td>
                <td>
                    {promocion.codigo}
                </td>
                <td>
                    {moment(promocion.desde).format('DD-MM-YYYY')}
                </td>
                <td>
                    {moment(promocion.hasta).format('DD-MM-YYYY')}
                </td>
                <td>
                    <input className="input" name="status" type="checkbox" id="status" checked={promocion.estatus === 1 ? true : false} disabled />
                </td>
                <td>
                    {promocion.descuento}
                </td>
                <td>
                    <a onClick={this.props.modalPromotionEdit.bind(this, 2, promocion)}>
                        <i class="fas fa-edit"></i>
                    </a>
                </td>
            </tr>
        )
    }
}

export default PromotionRow;