import React, { Component } from "react";
import { connect } from "react-redux";

// Web Components
import CategoryRow from "./category-row";

class CategoriesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numitems: 10
    };
  }
  render() {
    let categories = [];
    if (this.props.categories) {
      categories = this.props.categories.slice(
        (this.props.page - 1) * 10,
        this.props.page * 10
      );
    }
    return (
      <div className="clients-table">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th className="th-name">ID</th>
                <th className="th-name">CATEGORIA</th>
                <th> MODIFICAR </th>
              </tr>
            </thead>
            <tbody>
              {this.props.loading ? (
                <tr>
                  <td colSpan={3}>
                    <i className="fas fa-spinner fa-spin" />
                  </td>
                </tr>
              ) : (
                categories.map((category, index) => (
                  <CategoryRow key={index} {...category} />
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapSateToProps(state) {
  return {
    loading: state.categories_loading,
    categories: state.categories
  };
}

export default connect(mapSateToProps)(CategoriesTable);
