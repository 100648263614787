import React, { Component } from "react";

class DetailCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      esp: { descripcion: "" },
      ing: { descripcion: "" }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({
        esp: this.props.esp,
        ing: this.props.ing
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = {
      idCategoria: this.props.esp.idCategoria || 0,
      esp: event.target.category_name.value,
      ing: event.target.category_name_ing.value
    };
    this.props.saveCategory(data);
  }

  espChange(event) {
    const esp = this.state.esp;
    Object.assign(esp, { descripcion: event.target.value });
    this.setState({ esp: esp });
  }

  ingChange(event) {
    const ing = this.state.ing;
    Object.assign(ing, { descripcion: event.target.value });
    this.setState({ ing: ing });
  }

  render() {
    let btn_loading;
    if (this.props.add_loadig) {
      btn_loading = <i className="fas fa-spinner fa-spin" />;
    }
    return (
      <div className="add-category justify-center">
        <div className="container-add-product column align-center">
          <div className="row container">
            <div className="column justify-center">
              <form
                className="column gutters htmlForm-add-product"
                onSubmit={this.handleSubmit.bind(this)}
              >
                <div className="white-space-16" />
                <label htmlFor="category_name">
                  Nombre de la Categoía(ESP)
                </label>
                <div className="white-space-8" />
                <input
                  type="text"
                  name="category_name"
                  id="category_name"
                  className="input input-text"
                  required
                  value={this.state.esp.descripcion || ""}
                  onChange={this.espChange.bind(this)}
                />
                <div className="white-space-16" />
                <label htmlFor="category_name">
                  Nombre de la Categoía(ING)
                </label>
                <div className="white-space-8" />
                <input
                  type="text"
                  name="category_name_ing"
                  id="category_name_ing"
                  className="input input-text"
                  required
                  value={this.state.ing.descripcion || ""}
                  onChange={this.ingChange.bind(this)}
                />
                <div className="white-space-16" />
                <div className="btn-container-publish justify-center">
                  <button className="btn btn-aqua" type="submit">
                    <i className="fas fa-th-large" />
                    &nbsp;
                    {this.props.categoryStatus === "agregar"
                      ? "PUBLICAR CATEGORIA "
                      : "ACTUALIZAR CATEGORIA"}
                    {btn_loading}
                  </button>
                </div>                
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailCategory;
