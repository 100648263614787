import React, { Component } from 'react';

// FrontContoller
import View from './pages/view';

class App extends Component {
  render() {
    return (
      <View></View>
    );
  }
}

export default App;
