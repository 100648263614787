import React from "react";
import { Link } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import { IMG_URL } from '../../api/urls';

const ProductRow = function(product) {
  return (
    <tr className="table-row">
      <td className="table-image">
        <img
          src={
            IMG_URL + "/imagenes/obtener?id_imagen=" +
            product.idProducto
          }
          alt={product.idProducto}
          id="product-image"
          width="80px"
          height="80px"
          /*onError={()=>this.src = "https://via.placeholder.com/400?text=No+Image"}*/
        />
      </td>
      <td>{product.sku}</td>
      <td className="table-product-name">{product.nombre}</td>
      <td>
        <input type="checkbox" checked={product.existencia} disabled />
      </td>
      <td>
        <input type="checkbox" checked={product.relevante} disabled />
      </td>
      <td className="table-total">
        <FormattedNumber
          currency="EUR"
          style="currency"
          value={product.precio}
        />
      </td>
      <td className="table-total">
        <FormattedNumber
          minimumFractionDigits={3}
          maximumFractionDigits={3}
          format="0.000"
          value={product.peso}
        />
      </td>
      <td className="btn-container-modify-product">
        <Link
          to={"productos/modificar/" + product.idProducto}
          className="btn btn-aqua"
        >
          <i className="fas fa-pencil-alt" />
          &nbsp; EDITAR
        </Link>
      </td>
    </tr>
  );
};

export default ProductRow;
