import React, { Component } from "react";
import { connect } from "react-redux";

// Web Components
import Aside from "../components/aside/aside";
import DetailProduct from "../components/products/detail";
import * as action from "../redux/actions";

class DetalleProducto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationStatus: "none",
      esp: {},
      ing: {}
    };
    const success = this.success.bind(this);
    if (this.props.match.params.id) {
      this.props.dispatch(
        action.getDetails(this.props.match.params.id, success)
      );
    }
    if (!this.props.categories || this.props.categories.length <= 0) {
      this.props.dispatch(action.getCategories());
    }
    if (!this.props.lines || this.props.lines.length <= 0) {
      this.props.dispatch(action.getLines());
    }
  }

  componentWillMount() {
    let locationPath = this.props.location.pathname;
    if (locationPath.includes("modificar")) {
      this.setState({
        locationStatus: "modificar"
      });
    } else if (locationPath.includes("agregar")) {
      this.setState({
        locationStatus: "agregar"
      });
    } else {
      return this.state.locationStatus;
    }
  }

  success(response) {
    // console.log(response);
    const esp = response.find(product => product.idioma === "es");
    const ing = response.find(product => product.idioma === "en");
    this.setState({
      esp: esp || {},
      ing: ing || {}
    });
  }

  saveResponse(response) {
    if(response.length > 0) {
      window.location = '/productos';
    }
  }

  saveProductSuccess() {
    this.props.dispatch(
      action.getDetails(this.props.match.params.id, this.saveResponse.bind(this))
    );
  }
  saveProduct(data) {
    if (this.state.locationStatus === "agregar") {
      this.props.dispatch(action.addProduct(data));
    } else if (this.state.locationStatus === "modificar") {
      this.props.dispatch(
        action.updateProduct(
          data,
          this.props.match.params.id,
          this.saveProductSuccess.bind(this)
        )
      );
    }
  }
  deleteProducto(idProducto, response) {
    this.props.dispatch(
      action.deleteProducto(idProducto, response)
    );
  }

  render() {
    return (
      <div className="workspace">
        <Aside />
        <div className="control-content justify-center">
          <div className="container column">
            <div className="white-space-32" />
            <div className="align-center">
              <div>
                <h3 className="color-black">
                  {this.state.locationStatus === "agregar"
                    ? "AGREGAR PRODUCTO"
                    : "MODIFICAR PRODUCTO"}
                  {this.props.loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                </h3>
              </div>
            </div>
            <div className="white-space-16" />
            <DetailProduct
              productStatus={this.state.locationStatus}
              esp={this.state.esp}
              ing={this.state.ing}
              categories={this.props.categories}
              lines={this.props.lines}
              saveProduct={this.saveProduct.bind(this)}
              add_loadig={this.props.add_loadig}
              add_message={this.props.add_message}
              deleteProducto={this.deleteProducto.bind(this)}
            />
            <div className="white-space-32" />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.detail_loading,
    categories: state.categories,
    lines: state.lines,
    add_loadig: state.add_loading,
    add_message: state.add_message
  };
}

export default connect(mapStateToProps)(DetalleProducto);
