import * as types from './constants';

const initialState = {
	auth: false,
	products: [],
	categories: [],
	lines: [],
	clients: [],
	users: [],
	orders: [],
	dashboard: {},
	products_banner: [],
	product_detail: []
}

const reducer = (state = initialState, action) => {
	switch(action.type) {

		case types.LOAD:
			return {
				...state
			};
/* ==========================================================================
    USERS
========================================================================== */
	case types.LOGIN:
		return {
			...state,
			login_loading: true,
			login_error: undefined
		};

	case types.LOGIN_ERROR:
		return {
			...state,
			login_loading: false,
			login_error: action.message
		};

	case types.LOGIN_SUCCESS:
		return {
			...state,
			auth: true,
			login_loading: false,
			user: action.user
		};

	case types.LOGOUT:
		return {
			...state,
			auth: false,
			user: undefined
		};

		case types.REGISTAR_USUARIO:
		return {
			...state,
			login_error: undefined,
			sigin_message: undefined
		};
		case types.REGISTAR_USUARIO_ERROR:
		return {
			...state,
			login_loading: false,
			login_error: action.error,
			sigin_message: undefined
		};
		case types.REGISTAR_USUARIO_SUCCESS:
		return {
			...state,
			login_loading: false,
			login_error: action.error,
			sigin_message: action.message
		};

		case types.OBTENER_USUARIOS:
			return {
				...state,
				users_loading: true,
			};

		case types.OBTENER_USUARIOS_ERROR:
			return {
				...state,
				users_loading: false,
			};

		case types.OBTENER_USUARIOS_SUCCESS:
			return {
				...state,
				users_loading: false,
				users: action.users
			};

		case types.ELIMINAR_USUARIO:
			return {
				...state,
				users_loading: true,
			};

		case types.ELIMINAR_USUARIO_ERROR:
			return {
				...state,
				users_loading: false,
			};

		case types.ELIMINAR_USUARIO_SUCCESS:
			return {
				...state,
				users_loading: false,
				users: action.users
			};		
/* ==========================================================================
    PRODUCTS
========================================================================== */
		case types.ACTUALIZAR_PRODUCTO:
			return {
				...state,
				add_loading: true,
				add_message: undefined
			}

		case types.ACTUALIZAR_PRODUCTO_ERROR:
			return {
				...state,
				add_loading: false,
				add_message: action.message
			}

		case types.ACTUALIZAR_PRODUCTO_SUCCESS:
			return {
				...state,
				add_loading: false,
				add_message: action.message
			}

		case types.AGREGAR_PRODUCTO:
			return {
				...state,
				add_loading: true,
				add_message: undefined
			}

		case types.AGREGAR_PRODUCTO_ERROR:
			return {
				...state,
				add_loading: false,
				add_message: action.message
			}

		case types.AGREGAR_PRODUCTO_SUCCESS:
			return {
				...state,
				add_loading: false,
				add_message: action.message
			}

		case types.BUSCAR:
			return {
				...state,
				products_loading: true,
				products_error: undefined,
				products: []
			}

		case types.BUSCAR_ERROR:
			return {
				...state,
				products_loading: false,
				products_error: action.message
			}

		case types.BUSCAR_SUCCESS:
			return {
				...state,
				products_loading: false,
				products: action.products
			}
		
		case types.OBTENER_DETALLE:
			return {
				...state,
				add_message: '',
				detail_loading: true,
			}
		
		case types.OBTENER_DETALLE_ERROR:
			return {
				...state,
				detail_loading: false,
			}
		
		case types.OBTENER_DETALLE_SUCCESS:
			return {
				...state,
				detail_loading: false,
			}
/* ==========================================================================
    CATEGORIES
========================================================================== */
		case types.OBTENER_CATEGORIAS:
			return {
				...state,
				categories_loading: true,
			}

		case types.OBTENER_CATEGORIAS_ERROR:
			return {
				...state,
				categories_loading: false,
			}

		case types.OBTENER_CATEGORIAS_SUCCESS:
			return {
				...state,
				categories_loading: false,
				categories: action.categories
			}

		case types.OBTENER_CATEGORIAS_DETALLE:
			return {
				...state,
				category_detail_loading: true
			}

		case types.OBTENER_CATEGORIAS_DETALLE_ERROR:
			return {
				...state,
				category_detail_loading: false
			}

		case types.OBTENER_CATEGORIAS_DETALLE_SUCCESS:
			return {
				...state,
				category_detail_loading: false
			}

		case types.SAVE_CATEGORY:
			return {
				...state,
				save_category_loading: true
			}

		case types.SAVE_CATEGORY_ERROR:
			return {
				...state,
				save_category_loading: false
			}

		case types.SAVE_CATEGORY_SUCCESS:
			return {
				...state,
				save_category_loading: false
			}
/* ==========================================================================
    LINES
========================================================================== */
	case types.OBTENER_LINEAS:
		return {
			...state,
			lines_loading: true,
		};

	case types.OBTENER_LINEAS_ERROR:
		return {
			...state,
			lines_loading: false,
		};

	case types.OBTENER_LINEAS_SUCCESS:
		return {
			...state,
			lines_loading: false,
			lines: action.lines
		};

	case types.GET_CATEGORIES_BY_LINE:
		return {
			...state,
			categories_isLoading: true,
			lines_selected: action.data.idLinea
		};
		
	case types.GET_CATEGORIES_BY_LINE_ERROR:
		return {
			...state,
			categories_isLoading: false,
			error: action.error
		};

	case types.GET_CATEGORIES_BY_LINE_SUCCESS:
		return {
			...state,
			categories_isLoading: false,
			categories: action.categories
		};

/* ==========================================================================
   CLIENTS
========================================================================== */
		case types.OBTENER_CLIENTES:
		return {
			...state,
			clients_loading: true,
		};

		case types.OBTENER_CLIENTES_ERROR:
		return {
			...state,
			clients_loading: false,
		};

		case types.OBTENER_CLIENTES_SUCCESS:
		return {
			...state,
			clients_loading: false,
			clients: action.clients
		};

/* ==========================================================================
   ORDERS
========================================================================== */

		case types.OBTENER_PEDIDOS:
			return {
				...state,
				pedidos_loading: true,
				orders: [],
				pedidos_error: undefined
			}
		
		case types.OBTENER_PEDIDOS_ERROR:
			return {
				...state,
				pedidos_loading: false,
				orders: [],
				pedidos_error: action.error
			}

		case types.OBTENER_PEDIDOS_SUCCESS:
			return {
				...state,
				pedidos_loading: false,
				orders: action.orders
			}
		
		case types.OBTENER_PEDIDO_DETAIL:
			return {
				...state,
				pedido_detail_loading: true
			}

		case types.OBTENER_PEDIDO_DETAIL_ERROR:
			return {
				...state,
				pedido_detail_loading: false
			}
		
		case types.OBTENER_PEDIDO_DETAIL_SUCCESS:
			return {
				...state,
				pedido_detail_loading: false
			}
		
		case types.UPDATE_ORDER:
			return {
				...state,
				update_order_loading: true,
			}
		
		case types.UPDATE_ORDER_ERROR:
			return {
				...state,
				update_order_loading: false,
			}

		case types.UPDATE_ORDER_SUCCESS:
			const data = action.data;
			const orders = state.orders;
			const objIndex = orders.findIndex((obj => obj.idPedidos === data.id_pedido));
			orders[objIndex].estado = data.estado
			return {
				...state,
				update_order_loading: false,
				orders: orders
			}
		
		case types.DASHBOARD:
			return {
				...state,
				dashboard_loading: true,
				dashboard: {},
			};
		case types.DASHBOARD_ERROR:
			return {
				...state,
				dashboard_loading: false,
			};
		case types.DASHBOARD_SUCCESS:
			return {
				...state,
				dashboard_loading: false,
				dashboard: action.data
			};
		case types.ORDERS_DASHBOARD:
			return {
				...state,
				dashboard_orders_loading: true
			}
		case types.ORDERS_DASHBOARD_ERROR:
			return {
				...state,
				dashboard_orders_loading: false
			}
		case types.ORDERS_DASHBOARD_SUCCESS:
			return {
				...state,
				dashboard_orders_loading: false
			}

/* ==========================================================================
	BANNER
========================================================================== */

		case types.AGREGAR_PRODUCTO_BANNER:
			return {
				...state,
				add_banner_loading: true,
				add_message: undefined
			}	

		case types.AGREGAR_PRODUCTO_BANNER_ERROR:
			return {
				...state,
				add_banner_loading: false,
				add_message: action.message
			}

		case types.AGREGAR_PRODUCTO_BANNER_SUCCESS:
			return {
				...state,
				add_banner_loading: false,
				add_message: action.message
			}

		case types.ACTUALIZAR_PRODUCTO_BANNER:
			return {
				...state,
				update_loading_banner: true,
				update_message_banner: undefined
			}

		case types.ACTUALIZAR_PRODUCTO_BANNER_ERROR:
			return {
				...state,
				update_loading_banner: false,
				update_message_banner: action.message
			}

		case types.ACTUALIZAR_PRODUCTO_BANNER_SUCCESS:
			return {
				...state,
				update_loading_banner: false,
				update_message_banner: action.message
			}

		case types.GET_PRODUCTS_BANNER:
			return {
				...state,
				products_banner_loading: true
			};
		case types.GET_PRODUCTS_BANNER_ERROR:
			return {
				...state,
				products_banner_loading: false
			};
		case types.GET_PRODUCTS_BANNER_SUCCESS:
			return {
				...state,
				products_banner_loading: false,
				products_banner: action.products
			};

		case types.OBTENER_PRODUCTO_BANNER:
			return {
				...state,
				product_loading: true
			};
		case types.OBTENER_PRODUCTO_BANNER_ERROR:
			return {
				...state,
				product_loading: false
			};
		case types.OBTENER_PRODUCTO_BANNER_SUCCESS:
			return {
				...state,
				product_loading: false,
				product_detail: action.product
			};

		default:
			return {
				...state
			}
	}
}

export default reducer;