import React, { Component } from 'react';

// Web Components


class ClientTable extends Component {

    render() {
        if (this.props.order.cliente) {
            console.log(this.props.order.cliente);
        }
        return(
            <div className="cart-container justify-center">
                <div className="container">
                    <div className="table-responsive table-client">
                        <table>
                            <thead>
                                <tr>
                                    <th className="th-folio"> ID CLIENTE </th>
                                    <th className="th-status"> DATOS DEL CLIENTE </th>
                                    <th className="th-date"> ENVÍO </th>
									<th className="th-date" style={{width: '10%'}}> FACTURAR </th>
									<th className="th-date" style={{width: '10%'}}> DNI/NIF </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="table-row">
                                    <td className="table-idclient"> {this.props.order.idCliente} </td>
                                    <td className="table-clientInfo">
                                        {this.props.order.cliente}
                                    </td>
                                    <td className="table-clientAddress"> {this.props.order.direccion} </td>
									<td>
										{this.props.order.facturar? 'SI': 'NO'}
									</td>
									<td>
										{this.props.order.dni || "- - -"}
									</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
        );

    }

}

export default ClientTable;