import React, { Component } from 'react';
import { connect } from 'react-redux';

//Web Components
import * as action from '../../redux/actions';

// Images
import Images from '../../img/index';

class Header extends Component {

    renderAuth() {
        return (
            <div className="user-options justify-end">
                <div className="user align-center auto">
                    <p>
                        Hola, <b> {this.props.user.nombre} </b>
                    </p>
                </div>
                <div className="signout auto align-center" onClick={()=>this.props.dispatch(action.logout())}>
                    <p className="link">
                        <b>
                            <i className="fas fa-sign-out-alt"></i>&nbsp; SALIR
                        </b>
                    </p>
                </div>
            </div>
        )
    }
    renderLogin() {
        return (
            <div className="user-options justify-end">
                <div className="signout auto align-center" onClick={()=>window.location = '/login'}>
                    <p className="link">
                        <b>
                            <i className="fas fa-sign-out-alt"></i>&nbsp; Iniciar Sesión
                        </b>
                    </p>
                </div>
            </div>
        )
    }
    render() {
        let userInfo = this.renderLogin();
        if(this.props.auth) {
            userInfo = this.renderAuth();
        }
        return(
            <header className="flex justify-center bordered">
                <div className="container">
                    <div className="logo auto align-center">
                        <img src={Images['elfos-gourmet-logo']} alt="Elfos Logo" title="Elfos Logo" />
                    </div>
                    { userInfo }
                </div>                
            </header>
        );
    }

}
function mapStateToProps(state) {
    return {
        auth: state.auth,
        user: state.user
    }
}
export default connect(mapStateToProps)(Header);