/* ==========================================================================
    DASHBOARD
========================================================================== */

export const DASHBOARD = 'DASHBOARD';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';

export const ORDERS_DASHBOARD = 'ORDERS_DASHBOARD';
export const ORDERS_DASHBOARD_ERROR = 'ORDERS_DASHBOARD_ERROR';
export const ORDERS_DASHBOARD_SUCCESS = 'ORDERS_DASHBOARD_SUCCESS';

/* ==========================================================================
    USERS
========================================================================== */

export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT = 'LOGOUT';

/* ==========================================================================
    PRODUCTS
========================================================================== */
export const ACTUALIZAR_PRODUCTO = 'ACTUALIZAR_PRODUCTO';
export const ACTUALIZAR_PRODUCTO_ERROR = 'ACTUALIZAR_PRODUCTO_ERROR';
export const ACTUALIZAR_PRODUCTO_SUCCESS = 'ACTUALIZAR_PRODUCTO_SUCCESS';

export const AGREGAR_PRODUCTO = 'AGREGAR_PRODUCTO';
export const AGREGAR_PRODUCTO_ERROR = 'AGREGAR_PRODUCTO_ERROR';
export const AGREGAR_PRODUCTO_SUCCESS = 'AGREGAR_PRODUCTO_SUCCESS';

export const BUSCAR = 'BUSCAR';
export const BUSCAR_ERROR = 'BUSCAR_ERROR';
export const BUSCAR_SUCCESS = 'BUSCAR_SUCCESS';

export const ELIMINAR_PRODUCTO = 'ELIMINAR_PRODUCTO';
export const ELIMINAR_PRODUCTO_ERROR = 'ELIMINAR_PRODUCTO_ERROR';
export const ELIMINAR_PRODUCTO_SUCCESS = 'ELIMINAR_PRODUCTO_SUCCESS';

export const OBTENER_DETALLE = 'OBTENER_DETALLE';
export const OBTENER_DETALLE_ERROR = 'OBTENER_DETALLE_ERROR';
export const OBTENER_DETALLE_SUCCESS = 'OBTENER_DETALLE_SUCCESS';

/* ==========================================================================
    CATEGORIES
========================================================================== */

export const OBTENER_CATEGORIAS = 'OBTENER_CATEGORIAS';
export const OBTENER_CATEGORIAS_ERROR = 'OBTENER_CATEGORIAS_ERROR';
export const OBTENER_CATEGORIAS_SUCCESS = 'OBTENER_CATEGORIAS_SUCCESS';

export const OBTENER_CATEGORIAS_DETALLE = 'OBTENER_CATEGORIAS_DETALLE';
export const OBTENER_CATEGORIAS_DETALLE_ERROR = 'OBTENER_CATEGORIAS_DETALLE_ERROR';
export const OBTENER_CATEGORIAS_DETALLE_SUCCESS = 'OBTENER_CATEGORIAS_DETALLE_SUCCESS';

export const SAVE_CATEGORY = 'SAVE_CATEGORY';
export const SAVE_CATEGORY_ERROR = 'SAVE_CATEGORY_ERROR';
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS';

/* ==========================================================================
    LINES
========================================================================== */
export const OBTENER_LINEAS = 'OBTENER_LINEAS';
export const OBTENER_LINEAS_ERROR = 'OBTENER_LINEAS_ERROR';
export const OBTENER_LINEAS_SUCCESS = 'OBTENER_LINEAS_SUCCESS';

export const GET_CATEGORIES_BY_LINE = 'GET_CATEGORIES_BY_LINE';
export const GET_CATEGORIES_BY_LINE_ERROR = 'GET_CATEGORIES_BY_LINE_ERROR';
export const GET_CATEGORIES_BY_LINE_SUCCESS = 'GET_CATEGORIES_BY_LINE_SUCCESS';

/* ==========================================================================
    CLIENTES
========================================================================== */

export const OBTENER_CLIENTES = 'OBTENER_CLIENTES';
export const OBTENER_CLIENTES_ERROR = 'OBTENER_CLIENTES_ERROR';
export const OBTENER_CLIENTES_SUCCESS = 'OBTENER_CLIENTES_SUCCESS';

/* ==========================================================================
    USERS
========================================================================== */

export const REGISTAR_USUARIO = 'REGISTAR_USUARIO';
export const REGISTAR_USUARIO_ERROR = 'REGISTAR_USUARIO_ERROR';
export const REGISTAR_USUARIO_SUCCESS = 'REGISTAR_USUARIO_SUCCESS';

export const OBTENER_USUARIOS = 'OBTENER_USUARIOS';
export const OBTENER_USUARIOS_ERROR = 'OBTENER_USUARIOS_ERROR';
export const OBTENER_USUARIOS_SUCCESS = 'OBTENER_USUARIOS_SUCCESS';

export const ELIMINAR_USUARIO = "ELIMINAR_USUARIO";
export const ELIMINAR_USUARIO_ERROR = "ELIMINAR_USUARIO_ERROR";
export const ELIMINAR_USUARIO_SUCCESS = "ELIMINAR_USUARIO_SUCCESS";

export const LOAD = 'LOAD';

/* ==========================================================================
    ORDERS
========================================================================== */

export const OBTENER_PEDIDO_DETAIL = 'OBTENER_PEDIDO_DETAIL';
export const OBTENER_PEDIDO_DETAIL_ERROR = 'OBTENER_PEDIDO_DETAIL_ERROR';
export const OBTENER_PEDIDO_DETAIL_SUCCESS = 'OBTENER_PEDIDO_DETAIL_SUCCESS';

export const OBTENER_PEDIDOS = 'OBTENER_PEDIDOS';
export const OBTENER_PEDIDOS_ERROR = 'OBTENER_PEDIDOS_ERROR';
export const OBTENER_PEDIDOS_SUCCESS = 'OBTENER_PEDIDOS_SUCCESS';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';

/* ==========================================================================
	BANNER
========================================================================== */

export const AGREGAR_PRODUCTO_BANNER = 'AGREGAR_PRODUCTO_BANNER';
export const AGREGAR_PRODUCTO_BANNER_ERROR = 'AGREGAR_PRODUCTO_BANNER_ERROR ';
export const AGREGAR_PRODUCTO_BANNER_SUCCESS = 'AGREGAR_PRODUCTO_BANNER_SUCCESS';

export const ACTUALIZAR_PRODUCTO_BANNER = 'ACTUALIZAR_PRODUCTO_BANNER';
export const ACTUALIZAR_PRODUCTO_BANNER_ERROR = "ACTUALIZAR_PRODUCTO_BANNER_ERROR";
export const ACTUALIZAR_PRODUCTO_BANNER_SUCCESS = "ACTUALIZAR_PRODUCTO_BANNER_SUCCESS";

export const GET_PRODUCTS_BANNER = 'GET_PRODUCTS_BANNER';
export const GET_PRODUCTS_BANNER_ERROR = "GET_PRODUCTS_BANNER_ERROR";
export const GET_PRODUCTS_BANNER_SUCCESS = "GET_PRODUCTS_BANNER_SUCCESS";

export const OBTENER_PRODUCTO_BANNER = 'OBTENER_PRODUCTO_BANNER';
export const OBTENER_PRODUCTO_BANNER_ERROR = "OBTENER_PRODUCTO_BANNER_ERROR";
export const OBTENER_PRODUCTO_BANNER_SUCCESS = "OBTENER_PRODUCTO_BANNER_SUCCESS";