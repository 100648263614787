import React, { Component } from 'react';
import BannerRow from './banner-row';
import ModalBannerEdit from './../modals/banner-edit';

class BannerTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numitems: 10
        };
    }

    componentDidMount() {
        var modal = document.getElementById('modal-banner-edit');
        
        window.onclick = function (event) {
            if (event.target === modal) {
                modal.classList.add("hidden");
            }
        }
    }

    modalBannerEdit() {
        const modal = document.getElementById("modal-banner-edit");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    render() {
        let products = [];
        if (this.props.productsBanner) {
            products = this.props.productsBanner.slice((this.props.page - 1) * 10, this.props.page * 10);
        }
        return(
            <div className="products-table">
                <div className="cart-container justify-center">
                    <div className="column">
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="th-total">ID</th>
                                        <th className="th-total">IMAGEN</th>
                                        <th className="th-total">NOMBRE</th>
                                        <th className="th-tax">DESCRIPCIÓN</th>
                                        <th className="th-total">ESTATUS</th>
                                        <th className="th-total">EDITAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((item, index) =>
                                        <BannerRow key={index} product={item} modalBannerEdit={this.modalBannerEdit.bind(this)} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ModalBannerEdit />
            </div>
        )
    }
}

export default BannerTable;