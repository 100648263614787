import React, { Component } from 'react';
import PromotionRow from './promotion-row';

class PromotionTable extends Component {
    componentDidMount() {
        var modal = document.getElementById('modal-promotion-edit');

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.classList.add("hidden");
            }
        }
    }

    render() {
        let promotions = [];
        if (this.props.promotions) {
            promotions = this.props.promotions.slice(
                (this.props.page - 1) * 10,
                this.props.page * 10
            );;
        }

        return(
            <div className="products-table">
                <div className="cart-container justify-center">
                    <div className="column">
                        <div className="table-responsive">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="th-total">ID</th>
                                        <th className="th-total">CÓDIGO</th>
                                        <th className="th-total">FECHA</th>
                                        <th className="th-tax">VIGENCIA</th>
                                        <th className="th-total">ESTATUS</th>
                                        <th className="th-total">% DESCUENTO</th>
                                        <th className="th-total">MODIFICAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.loading ? (
                                        <tr>
                                            <td colSpan={7}>
                                                <i className="fas fa-spinner fa-spin" />
                                            </td>
                                        </tr>
                                    ) : 
                                    promotions.map((item, index) => 
                                        <PromotionRow key={index} promotion={item} modalPromotionEdit={this.props.modalPromotionEdit.bind(this)} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PromotionTable;