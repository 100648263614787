import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";

// Web Components
import Aside from "../components/aside/aside";
import Dates from "../components/oders/dates";
import Filters from "../components/oders/filters";
import OrdersTable from "../components/oders/orders-table";
import Paginator from "../components/paginator/paginator";
import OrderDetail from "../components/modals/order-details";
import * as action from "../redux/actions";

class Pedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      filters: {},
      order: 0
    };

    this.findOrders(dayjs().add(-7, 'day'), dayjs());
  }
  findOrders(start, end) {
    const data = {
      start: dayjs(start).format("YYYY-MM-DD"),
      end: dayjs(end).format("YYYY-MM-DD")
    };
    this.props.dispatch(action.getOrders(data));
    this.setState({ filters: {}, page: 1 });
  }

  filterOrders(data) {
    this.setState({ filters: data, page: 1 });
    this.render();
  }

  selectorder(order) {
    this.setState({ order: 0 });
    this.render();
    this.setState({ order: order });
  }

  setpage(page) {
    this.setState({ page: page });
  }

  render() {
    let orders = this.props.orders ? this.props.orders : [];
    if (this.state.filters.folio) {
      orders = orders.filter(
        order => order.folio.toLowerCase().indexOf(this.state.filters.folio.toLowerCase()) !== -1
      );
    }
    if (this.state.filters.estado) {
      orders = orders.filter(
        order => order.estado.indexOf(this.state.filters.estado) !== -1
      );
    }
    return (
      <div className="workspace">
        <Aside />
        <div className="control-content justify-center">
          <div className="container column">
            <div className="white-space-32" />
            <h3 className="color-black">PEDIDOS</h3>
            <div className="white-space-8" />
            <Dates
              setDates={this.findOrders.bind(this)}
              loading={this.props.loading}
            />
            <div className="white-space-32" />            
            <Filters filter={this.filterOrders.bind(this)} />
            <div className="white-space-16" />
            <OrdersTable
              page={this.state.page}
              orders={orders}
              selectorder={this.selectorder.bind(this)}
            />
            <div className="white-space-32" />
            <Paginator
              setpage={this.setpage.bind(this)}
              items={Math.ceil(orders.length / 10)}
            />
            <div className="white-space-32"></div>
          </div>          
        </div>        
        <OrderDetail
          order={this.state.order}
          dispatch={this.props.dispatch}
          loading={this.props.update_order_loading}
        />
      </div>      
    );
  }

  componentDidMount() {
    let modal = document.getElementById("modal-order");

    let btn = document.getElementById("open-modal-order");

    if (btn) {
      btn.onclick = function() {
        modal.classList.remove("hidden");
      };
    }

    window.onclick = function(event) {
      if (event.target === modal) {
        modal.classList.add("hidden");
      }
    };

    window.onkeydown = event => {
      if (event.keyCode === 27) {
        modal.classList.add("hidden");
      } else {
        return 0;
      }
    };
  }
}

function mapStateToProps(state) {
  return {
    orders: state.orders,
    loading: state.pedidos_loading,
    update_order_loading: state.update_order_loading
  };
}

export default connect(mapStateToProps)(Pedidos);
