import React, { Component } from 'react';
import { Request } from '../../api/apiservice';
import moment from 'moment-timezone';
import dayjs from 'dayjs';

const req = new Request();

class PromotionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_codigo: '',
            codigo: '',
            fecha: '',
            vigencia: '',
            estatus: '',
            descuento: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            console.log(this.props);
            this.setState({
                id_codigo: this.props.promotion.id_codigopromocion,
                codigo: this.props.promotion.codigo,
                fecha: this.props.promotion.desde,
                vigencia: this.props.promotion.hasta,
                estatus: this.props.promotion.estatus,
                descuento: this.props.promotion.descuento
            });
        }
    }

    async savePromotion(e) {
        e.preventDefault();

        if (this.props.tipo === 1) {
            const form = e.target;
            const data = {
                codigo: form.code.value,
                fecha: form.desde.value,
                vigencia: form.hasta.value,
                estatus: 1,
                descuento: form.discount.value
            };
            console.log(data);

            const res = await req.post('/admin/promociones/create', data);
            if (res) {
                if (res.created) {
                    alert(res.message);
                    window.location.reload();
                } else {
                    alert(res.message);
                }
            }
        } 

        if (this.props.tipo === 2) {
            const form = e.target;
            const data = {
                id_codigo: this.state.id_codigo,
                codigo: form.code.value,
                fecha: form.desde.value,
                vigencia: form.hasta.value,
                estatus: form.status.value ? 1 : 0,
                descuento: form.discount.value
            };
            console.log(data);

            const res = await req.post('/admin/promociones/update', data);
            if (res) {
                if (res.updated) {
                    alert(res.message);
                    window.location.reload();
                } else {
                    alert(res.message);
                }
            }
        }
    }

    render() {
        return(
            <div className="flex modal justify-center hidden" id="modal-promotion-edit">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            PROMOCIÓN
                        </h2>
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-32"></div>
                        <form className="modal-body-container column justify-center align-center" onSubmit={this.savePromotion.bind(this)}>
                            <div className="modal-body-data column justify-center align-center">
                                <div className="justify-between align-center">
                                    <label>CÓDIGO</label>&nbsp;
                                    <input className="input input-text" type="text" name="code" id="code" 
                                        value={this.state.codigo ? this.state.codigo : ''} 
                                        onChange={(event) => { this.setState({ codigo: event.target.value }) }} required/>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="justify-between align-center">
                                    <label>DESCUENTO</label>&nbsp;
                                    <input className="input input-text" type="text" name="discount" id="discount" 
                                        value={this.state.descuento ? this.state.descuento : ''}
                                        onChange={(event) => { this.setState({ descuento: event.target.value }) }} required/>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="justify-between align-center">
                                    <label>DESDE</label>&nbsp;
                                    <input className="input input-text" type="date" name="desde" id="desde" 
                                        value={this.state.fecha ? dayjs(this.state.fecha).format('YYYY-MM-DD') : ''}
                                        onChange={(event) => this.setState({ fecha: event.target.value }) } required/>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="justify-between align-center">
                                    <label>HASTA</label>&nbsp;
                                    <input className="input input-text" type="date" name="hasta" id="hasta"
                                        value={this.state.vigencia ? dayjs(this.state.vigencia).format('YYYY-MM-DD') : ''}
                                        onChange={(event) => this.setState({ vigencia: event.target.value }) } required />
                                </div>
                                {this.props.tipo === 2 ?
                                <React.Fragment>
                                    <div className="white-space-16"></div>
                                    <div className="justify-between align-center">
                                        <div>
                                            <label>ESTATUS</label>
                                        </div>
                                        <div className="justify-center">
                                            <input type="checkbox" name="status" id="status" checked={this.state.estatus === 1 ? true : false}
                                                onChange={(event) => {
                                                    const status = event.target.checked ? 1 : 0;
                                                    this.setState({ estatus: status });
                                                }} />
                                        </div>
                                    </div>
                                </React.Fragment> : null}
                            </div>
                            <div className="white-space-32"></div>
                            <div className="btn-container-login">
                                <button type="submit" className="btn btn-aqua" id="savePromotion">
                                    <i className="fas fa-sign-in-alt"></i>&nbsp; GUARDAR PROMOCIÓN
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default PromotionModal;