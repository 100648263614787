import React, { Component } from 'react';
import { connect } from 'react-redux';

// Web Components
import Aside from '../components/aside/aside';
import DashBoardCards from '../components/dashboard/cards';
import SummaryOrders from '../components/dashboard/summary-orders';
import * as action from '../redux/actions';

class Tablero extends Component {
    constructor(props) {
        super(props);
        this.state ={}

        this.props.dispatch(action.getDashboard())
    }
    componentDidMount() {
        this.props.dispatch(action.getDashboard())
    }
    render() {
        const dashboard = this.props.dashboard? this.props.dashboard : {}
        return(
            <div className="workspace">
                <Aside />
                <div className="control-content justify-center">
                    <div className="container column">
                        <DashBoardCards products = { dashboard.productos } clients = { dashboard.clientes } orders={dashboard.ordenes} loading={this.props.dashboard_loading}/>
                        <SummaryOrders />
                        <div className="white-space-32"></div>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        products: state.products,
        clients: state.clients,
        dashboard: state.dashboard,
        dashboard_loading: state.dashboard_loading
    }
}

export default connect(mapStateToProps)(Tablero);