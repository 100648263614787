import React from "react";
import { Link } from "react-router-dom";

const CategoryRow = function(category) {
  return (
    <tr className="table-row" id="open-modal-order">
      <td>{category.idCategoria}</td>
      <td>{category.descripcion}</td>
      <td className="btn-container-modify-product">
        <Link
          to={"categorias/modificar/" + category.idCategoria}
          className="btn btn-aqua"
        >
          <i className="fas fa-pencil-alt" />
          &nbsp; EDITAR
        </Link>
      </td>
    </tr>
  );
};

export default CategoryRow;
