import React from 'react';

const ClientsRow = function (client) {

    return(
        <tr className="table-row" id="open-modal-order">
            <td>
                { client.usuario }
            </td>
            <td>
                { client.nombre + ' ' + client.apellidos }
            </td>
            <td>
                { client.email }
            </td>
            <td>
                { client.telefono }
            </td>
            <td>
                { 
                    client.direccion ? (client.direccion) :
                    ( "No existe una dirección registrada" )
                }
            </td>
        </tr>
    );

}

export default ClientsRow;