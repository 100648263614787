import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Web Components
import Aside from '../components/aside/aside';
import Filters from '../components/products/filters';
import ProductsTable from '../components/products/products-table';
import Paginator from '../components/paginator/paginator';
import * as action from "../redux/actions";

class Productos extends Component {
    constructor(props) {
        super(props);
        this.state={
            page:1,
        }
        this.props.dispatch(action.getCategories());
        this.props.dispatch(action.getLines());
        this.props.dispatch(action.findProducts({busqueda: ''}));
    }
    setpage(page) {
        this.setState({page: page})
    }
    render() {
        return(
            <div className="workspace">
                <Aside />
                <div className="control-content justify-center">
                    <div className="container column">
                        <div className="white-space-32"></div>
                        <div className="align-center">
                            <div>
                                <h3 className="color-black">
                                    TODOS LOS PRODUCTOS
                                </h3>
                            </div>
                            <div className="justify-end">
                                <Link  to="productos/agregar" className="btn btn-aqua" id="open-modal-add-user">
                                    <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR PRODUCTO
                                </Link>
                            </div>
                        </div>
                        <div className="white-space-16"></div>
                        <h5 className="color-black">
                            FILTRAR PRODUCTOS
                        </h5>
                        <div className="white-space-8"></div>
                        <Filters setpage={this.setpage.bind(this)}/>
                        <div className="white-space-16"></div>
                        <ProductsTable page={this.state.page}/>
                        <div className="white-space-32"></div>
                        <Paginator setpage={this.setpage.bind(this)} items={Math.ceil(this.props.products.length / 10)}/> 
                        <div className="white-space-32"></div>
                    </div>
                </div>
            </div>
        );
    }

}
function mapStateToProps(state) {
    return {
        products: state.products
    }
}

export default connect(mapStateToProps)(Productos);