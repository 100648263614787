import React, { Component } from 'react';
import * as action from '../../redux/actions';
import { connect } from 'react-redux';

class UsersRow extends Component {

    constructor(props) {
        super(props);
    }

    deleteUser(idUsuario, event) {
        this.props.dispatch(action.deleteUser(idUsuario));  
        setTimeout(function(){window.location = "usuarios";},1500);
    }

    render() {        
        return (
            <tr className="table-row" id="open-modal-order">
                <td>
                    {this.props.user.id_Usuario}
                </td>
                <td>
                    {this.props.user.usuario}
                </td>
                <td>
                    <button className="btn btn-red" onClick = {this.deleteUser.bind(this, this.props.user.id_Usuario)}>
                        ELIMINAR
                    </button>
                </td>
            </tr>
        );
    }    

}
export default connect()(UsersRow);