import React, { Component } from 'react';

// Web Components
import ProductRow from './products-row';

class OrderProducts extends Component {

    render() {
        console.log(this.props.products);
        return(
            <div className="cart-container justify-center">
                <div className="container column">
                    <div className="white-space-32"></div>
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th> SKU </th>
                                    <th className="th-name"> PRODUCTO </th>
                                    <th> CANTIDAD </th>                                    
                                    <th> PRECIO </th>
                                    <th> PROMOCIÓN </th>
                                    <th> TOTAL </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.products.map((product, index) =>
                                    <ProductRow product={product} key={index}/>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );

    }

}

export default OrderProducts;