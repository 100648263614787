import React, { Component } from "react";
import { connect } from "react-redux";

// Web Components
import OrdersRow from "./orders-row";

class OrdersTable extends Component {
  render() {
    let orders = [];
    if (this.props.orders) {
      orders = this.props.orders.slice((this.props.page - 1) * 10, this.props.page * 10);
    }
    return (
      <div className="summary-orders-table">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th> FOLIO </th>
                <th> FECHA </th>
                <th> IMPORTE </th>
                <th> ESTADO </th>
                <th> DETALLES </th>
              </tr>
            </thead>
            <tbody>
              {this.props.error ? (
                <tr>
                  <td colSpan="6">{this.props.error}</td>
                </tr>
              ) : this.props.loading ? (
                <tr>
                  <td colSpan="6">
                    <i className="fas fa-spinner fa-spin" />
                  </td>
                </tr>
              ) : (
                orders.map((order, index) => (
                  <OrdersRow key={index} order={order} selectorder={this.props.selectorder}/>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
function mapSateToProps(state) {
  return {
    loading: state.pedidos_loading,
    error: state.pedidos_error
  };
}

export default connect(mapSateToProps)(OrdersTable);
