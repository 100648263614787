import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from '../../redux/actions';

class UserModal extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            login_error: '',
            error_class: 'label-error'
        }
    }

    cipher(text) {
        const string64 = new Buffer(text).toString('base64');
        return string64;
    }

    registerUser(event) {
        event.preventDefault();
        let user = {
            user: event.target.username.value,
            password: this.cipher(event.target.password.value),
            status: 1
        }
        this.props.dispatch(action.registerUser(user));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.login_error !== nextProps.login_error) {
            this.setState({
                login_error: nextProps.login_error,
            })
        }

        if (this.props.sigin_message !== nextProps.sigin_message) {
            this.setState({
                login_error: nextProps.sigin_message,
            })
        }
    }

    render() {
        return(
            <div className="flex modal justify-center hidden" id="modal-add-user">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            NUEVO USUARIO
                        </h2>
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-16"></div>
                        <form className="modal-body-container column" onSubmit={this.registerUser.bind(this)}>
                            <div className="users-data justify-between">
                                <div className="column auto">
                                    <label htmlFor="user" className="color-black">
                                        USUARIO
                                    </label>
                                    <div className="white-space-8"></div>
                                    <input type="text" name="username" className="input input-text" />
                                    <div className="white-space-16"></div>
                                </div>
                                <div className="column auto">
                                    <label htmlFor="password" className="color-black">
                                        CONTRASEÑA
                                    </label>
                                    <div className="white-space-8"></div>
                                    <input type="password" name="password" className="input input-text" />
                                    <div className="white-space-16"></div>
                                </div>
                            </div>
                            <div className="white-space-8"></div>
                            <strong className="color-darkGreen" id="register-status">
                                {
                                    this.state.login_error === '1' ? this.registerOk() :
                                        this.state.login_error === '2' ? "" :
                                            this.state.login_error === '3' ? "Usuario en uso o datos inválidos" :
                                                ""
                                }
                            </strong>
                            <div className="white-space-8"></div>
                            <div className="btn-container-login">
                                <button type="submit" className="btn btn-aqua" id="registerUser">
                                    <i className="fas fa-sign-in-alt"></i>&nbsp; REGISTAR USUARIO
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );

    }

    registerOk() {
        alert("Registro Exitoso");
    }

}

function mapStateToProps(state) {
    return {
        login_error: state.login_error,
        loading: state.login_loading,
        sigin_message: state.sigin_message
    };
}

export default connect(mapStateToProps)(UserModal);