import React, { Component } from 'react';

// Web Components
import Aside from '../components/aside/aside';
import { Link } from 'react-router-dom';
import PromotionTable from '../components/products/promotion-table';
import ModalPromotionEdit from '../components/modals/promotion-modal';
import { Request } from '../api/apiservice';
import Paginator from "../components/paginator/paginator";

const req = new Request();

class Promociones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promotions: [],
            promotion: [],
            tipo: '',
            page: 1
        }
    }

    componentDidMount() {
        var modal = document.getElementById('modal-promotion-edit');

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.classList.add("hidden");
            }
        }

        this.getPromotions();
    }

    modalPromotionEdit(tipo, data) {
        this.setState({ tipo: tipo, promotion: data });
        const modal = document.getElementById("modal-promotion-edit");
        modal.style.display = 'flex';
        modal.classList.remove('hidden');
    }

    async getPromotions() {
        this.setState({ loading: true });
        const res = await req.get('/admin/promociones/get');
        if (res) {
            if (res.promociones) {
                this.setState({ promotions: res.promociones });
            }
        }
        this.setState({ loading: false });
    }

    setpage(page) {
        this.setState({ page: page });
    }
    
    render() {
        let promociones = [];
        if (this.state.promotions) {
            promociones = this.state.promotions;
        }

        return(
            <div className="workspace">
                <Aside />
                <div className="control-content justify-center">
                    <div className="container column">
                        <div className="white-space-32" />
                        <h3 className="color-black">PROMOCIONES</h3>
                        <div className="white-space-24" />
                        <div className="">
                            <div className="justify-start">
                                <button className="btn btn-aqua" onClick={this.getPromotions.bind(this)}>
                                    <i className="fas fa-sync-alt"></i>&nbsp; ACTUALIZAR
                                </button>
                            </div>
                            <div className="justify-end">
                                <a className="btn btn-aqua" onClick={this.modalPromotionEdit.bind(this, 1, {})}>
                                    <i className="fas fa-plus-circle"></i>&nbsp; AGREGAR PROMOCIÓN
                                </a>
                            </div>
                        </div>
                        <div className="white-space-24" />
                        <PromotionTable loading={this.state.loading} promotions={promociones} modalPromotionEdit={this.modalPromotionEdit.bind(this)} page={this.state.page} />
                        {promociones.length > 10 ?
                        <React.Fragment> 
                            <div className="white-space-32"></div>
                            <Paginator
                                setpage={this.setpage.bind(this)}
                                items={Math.ceil(promociones.length / 10)}
                            />
                        </React.Fragment> : null}
                    </div>
                </div>
                <ModalPromotionEdit tipo={this.state.tipo} promotion={this.state.promotion} />
            </div>
        )
    }
}

export default Promociones;