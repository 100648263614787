import React from "react";
import { FormattedNumber } from "react-intl";
import dayjs from "dayjs";

const SummaryRow = function(props) {
  return (
    <tr class="table-row">
      <td>{props.row.folio}</td>
      <td>
        {dayjs(props.row.fecha.replace("Z", "")).format("DD/MM/YYYY HH:mm")}
      </td>
      <td>
        <FormattedNumber
          currency="EUR"
          style="currency"
          value={props.row.importe}
        />
      </td>
    </tr>
  );
};

export default SummaryRow;
