import React, { Component } from "react";
import { connect } from "react-redux";

//WebComponents
import * as action from "../redux/actions";
import { cifrar } from '../components/encrypt/encrypt';

// Images
import Images from "../img/index";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillReceiveProps(nextProps) {
      if (this.props.auth !== nextProps.auth && nextProps.auth) {
        window.location = '/tablero';
      }
  }

  login(event) {
    event.preventDefault();
    const username = event.target.username.value;
    const password = cifrar(event.target.password.value);
    const data = {
      username,
      password
    };
    this.props.dispatch(action.login(data));
  }

  render() {
    return (
      <div className="login justify-center align-center">
        <div className="container column">
          <div className="justify-center">
            <div className="login-modal column">
              <div className="white-space-32" />
              <div className="logo justify-center">
                <img
                  src={Images["elfos-gourmet-logo"]}
                  alt="Logo Elfos"
                  title="Logo Elfos"
                />
              </div>
              <div className="white-space-16" />
              <h2 className="color-black text-center">ADMINISTRACIÓN</h2>
              <div className="white-space-32" />
              <form className="column align-center" onSubmit={this.login.bind(this)} >
                <input
                  type="text"
                  name="username"
                  className="input input-text"
                  placeholder="USUARIO"
                  required
                />
                <div className="white-space-8" />
                <input
                  type="password"
                  name="password"
                  className="input input-text"
                  placeholder="CONTRASEÑA"
                  required
                />
                <div className="white-space-24" />
                <div className="column align-center">
                    {this.props.error}
                </div>
                <div className="white-space-24" />
                <div className="btn-container-login">
                  <button className="btn btn-aqua" type="submit">
                    {this.props.loading ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                      <i className="fas fa-sign-in-alt" />
                    )}
                    &nbsp; INGRESAR
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    loading: state.login_loading,
    error: state.login_error
  };
}
export default connect(mapStateToProps)(Login);
