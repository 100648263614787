import * as types from "./constants";
/* ==========================================================================
    DASHBOARD
========================================================================== */
export function getDashboard() {
  return {
    type: types.DASHBOARD
  };
}

export function getOrdersDash(start, end, response) {
  return {
    type: types.ORDERS_DASHBOARD,
    data: {
      start: start,
      end: end
    },
    response
  };
}

/* ==========================================================================
    USERS
========================================================================== */
export function login(data) {
  return {
    type: types.LOGIN,
    data
  };
}

export function logout() {
  window.location = "/login";
  return {
    type: types.LOGOUT
  };
}
/* ==========================================================================
    PRODUCTS
========================================================================== */
export function addProduct(data) {
  return {
    type: types.AGREGAR_PRODUCTO,
    data
  };
}

export function updateProduct(data, id_producto, success) {
  return {
    type: types.ACTUALIZAR_PRODUCTO,
    data,
    id_producto,
    success
  };
}

export function deleteProducto(id_producto, response) {
  return {
    type: types.ELIMINAR_PRODUCTO,
    data: { id_producto: id_producto },
    response
  };
}

export function findProducts(data) {
  return {
    type: types.BUSCAR,
    data
  };
}

export function getDetails(data, success) {
  return {
    type: types.OBTENER_DETALLE,
    data,
    success
  };
}

/* ==========================================================================
    CATEGORIES
========================================================================== */
export function getCategories() {
  return {
    type: types.OBTENER_CATEGORIAS
  };
}

export function getCategoryDetails(id, response) {
  return {
    type: types.OBTENER_CATEGORIAS_DETALLE,
    id,
    response
  }
}

export function saveCategory(data, response) {
  return {
    type: types.SAVE_CATEGORY,
    data,
    response
  }
}

/* ==========================================================================
    LINES
========================================================================== */
export function getLines() {
  return {
    type: types.OBTENER_LINEAS
  };
}

export function getCategoriesByLine(idLinea, idioma) {
	return {
		type: types.GET_CATEGORIES_BY_LINE,		
		data: {idLinea: idLinea, idioma, idioma}
	}
};

/* ==========================================================================
	CLIENTS
========================================================================== */

export function getAllClients() {
  return {
    type: types.OBTENER_CLIENTES
  };
}

/* ==========================================================================
	USERS
========================================================================== */

export function registerUser(data) {
  return {
    type: types.REGISTAR_USUARIO,
    data
  };
}

export function getAllUsers() {
  return {
    type: types.OBTENER_USUARIOS
  };
}

export function deleteUser(data) {
  return {
    type: types.ELIMINAR_USUARIO,
    data
  };
}

/* ==========================================================================
	ORDERS
========================================================================== */

export function getOrders(data) {
  return {
    type: types.OBTENER_PEDIDOS,
    data
  };
}

export function getOrderDetail(data, response) {
  return {
    type: types.OBTENER_PEDIDO_DETAIL,
    data,
    response
  };
}

export function updateOrder(id_pedido, tipo, estado, modal, response) {
  modal.classList.add("hidden");
  return {
    type: types.UPDATE_ORDER,
    response,
    data: {
      id_pedido: id_pedido,
      estado: tipo === "CANCELAR" ? "CANCELADO" : estado
    }
  };
}

/* ==========================================================================
	BANNER
========================================================================== */

export function addProductBanner(data) {
  return {
    type: types.AGREGAR_PRODUCTO_BANNER,
    data
  };
}

export function updateProductBanner(data) {
  return {
    type: types.ACTUALIZAR_PRODUCTO_BANNER,
    data
  };
}

export function getProductsBanner() {
  return {
    type: types.GET_PRODUCTS_BANNER
  }
};

export function getProductoBanner(data) {
  return {
    type: types.OBTENER_PRODUCTO_BANNER,
    data
  }
};