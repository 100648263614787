import request from "superagent";
import * as types from "../redux/constants";
import { URL_API } from "./urls";

const apiService = store => next => action => {
  const user = store.getState().user;

  next(action);
  switch (action.type) {
    case types.LOGIN:
      request
        .post(URL_API + "/admin/users/login")
        .send(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.LOGIN_ERROR,
              error: err
            });
          }
          if (response.body.error) {
            console.log(response.body.message);
            return next({
              type: types.LOGIN_ERROR,
              message: response.body.message
            });
          } else {
            return next({
              type: types.LOGIN_SUCCESS,
              user: response.body.data
            });
          }
        });
      break;

    case types.REGISTAR_USUARIO:
      request
        .post(URL_API + "/admin/users/register")
        .send(action.data)
        .end((err, res) => {
          if (err) {
            console.log("Apiservice: ", err);
            return next({
              type: types.REGISTAR_USUARIO_ERROR,
              error: err
            });
          }
          if (res.body.error) {
            console.log("Apiservice response: ", res.body.error);
            return next({
              type: types.REGISTAR_USUARIO_ERROR,
              error: res.body.error
            });
          } else {
            return next({
              type: types.REGISTAR_USUARIO_SUCCESS,
              message: res.body.data.mensaje
            });
          }
        });
      break;

    case types.ELIMINAR_USUARIO:
      request
        .post(URL_API + "/admin/users/eliminar")
        .send({ idUsuario: action.data })
        .end((err, res) => {
          if (err) {
            console.log("Apiservice: ", err);
            return next({
              type: types.ELIMINAR_USUARIO_ERROR,
              error: err
            });
          }
          if (res.body.error) {
            console.log("Apiservice response: ", res.body.error);
            return next({
              type: types.ELIMINAR_USUARIO_ERROR,
              error: res.body.error
            });
          } else {
            return next({
              type: types.ELIMINAR_USUARIO_SUCCESS,
              message: res.body.data.mensaje
            });
          }
        });
      break;

    case types.ACTUALIZAR_PRODUCTO:
      request
        .post(URL_API + "/admin/products/actualizar")
        .send(action.data)
        .send({ id_producto: action.id_producto })
        .send({ id_usuario: user.id_Usuario })
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.ACTUALIZAR_PRODUCTO_ERROR,
              message: "request error"
            });
          }
          if (response.body.error) {
            console.log(response.body);
            return next({
              type: types.ACTUALIZAR_PRODUCTO_ERROR,
              message: response.body.message
            });
          } else {
            action.success();
            return next({
              type: types.ACTUALIZAR_PRODUCTO_SUCCESS,
              message: response.body.message
            });
          }
        });
      break;

      case types.ELIMINAR_PRODUCTO:
      request
        .post(URL_API + "/admin/products/eliminar")
        .send(action.data)
        .send({ id_usuario: user.id_Usuario })
        .end((err, response) => {
          if (err) {
            console.log(err);
            action.response({eliminado: false, message: "Request Error"});
            return next({
              type: types.ACTUALIZAR_PRODUCTO_ERROR,
              message: "Request Error"
            });
          }
          if (response.body.error) {
            console.log(response.body);
            action.response({eliminado: false, message: response.body.message});
            return next({
              type: types.ACTUALIZAR_PRODUCTO_ERROR,
              message: response.body.message
            });
          } else {
            action.response({eliminado: true, message: response.body.message});
            return next({
              type: types.ACTUALIZAR_PRODUCTO_SUCCESS,
              message: response.body.message
            });
          }
        });
      break;

    case types.AGREGAR_PRODUCTO:
      request
        .post(URL_API + "/admin/products/agregar")
        .send(action.data)
        .send({ id_usuario: user.id_Usuario })
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.AGREGAR_PRODUCTO_ERROR,
              message: "request error"
            });
          }
          if (response.body.error) {
            console.log(response.body.error);
            return next({
              type: types.AGREGAR_PRODUCTO_ERROR,
              message: response.body.message
            });
          } else {
            return next({
              type: types.AGREGAR_PRODUCTO_SUCCESS,
              message: response.body.message
            });
          }
        });
      break;

    case types.BUSCAR:
      request
        .post(URL_API + "/admin/products/buscar")
        .send(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.BUSCAR_ERROR,
              error: err
            });
          }
          if (response.body.error) {
            console.log(response.body.error);
            return next({
              type: types.BUSCAR_ERROR,
              message: response.body.message
            });
          } else {
            return next({
              type: types.BUSCAR_SUCCESS,
              products: response.body.data
            });
          }
        });
      break;

    case types.OBTENER_DETALLE:
      request
        .get(URL_API + "/admin/products/detail")
        .query({ id: action.data })
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.OBTENER_DETALLE_ERROR,
              error: err
            });
          }
          if (response.body.error) {
            console.log(response.body.error);
            return next({
              type: types.OBTENER_DETALLE_ERROR,
              message: response.body.message
            });
          } else {
            action.success(response.body.data);
            return next({
              type: types.OBTENER_DETALLE_SUCCESS,
              products: response.body.data
            });
          }
        });
      break;

    case types.OBTENER_CATEGORIAS:
    console.log('hello')
      request
        .get(URL_API + "/admin/categories")
        .query({ idioma: "es" })
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.OBTENER_CATEGORIAS_ERROR,
              error: err
            });
          }
          if (response.body.error) {
            console.log(response.body.error);
            return next({
              type: types.OBTENER_CATEGORIAS_ERROR,
              message: response.body.message
            });
          } else {
            return next({
              type: types.OBTENER_CATEGORIAS_SUCCESS,
              categories: response.body.data
            });
          }
        });
      break;

    case types.OBTENER_CATEGORIAS_DETALLE:
        request
        .get(URL_API + "/admin/categories/" + action.id)
        .end((err, response) => {
          if (err) {
            console.log(err);
            action.response({error: true, message: 'Request Error'})
            return next({
              type: types.OBTENER_CATEGORIAS_DETALLE_ERROR
            });
          }
          if (response.body.error) {
            console.log(response.body.message);
            action.response({error: true, message: response.body.message})
            return next({
              type: types.OBTENER_CATEGORIAS_DETALLE_ERROR
            });
          } else {
            action.response(response.body.data)
            return next({
              type: types.OBTENER_CATEGORIAS_DETALLE_SUCCESS
            });
          }
        });
    break;

    case types.SAVE_CATEGORY:
        request
        .post(URL_API + "/admin/categories/save")
        .send(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            action.response({error: true, message: 'Request Error'})
            return next({
              type: types.SAVE_CATEGORY_ERROR
            });
          }
          if (response.body.error) {
            console.log(response.body.message);
            action.response({error: true, message: response.body.message})
            return next({
              type: types.SAVE_CATEGORY_ERROR
            });
          } else {
            action.response(response.body.data)
            return next({
              type: types.SAVE_CATEGORY_SUCCESS
            });
          }
        });
    break;

    case types.OBTENER_LINEAS:
      request.get(URL_API + "/lineas").end((err, response) => {
        if (err) {
          console.log(err);
          return next({
            type: types.OBTENER_LINEAS_ERROR,
            error: err
          });
        }
        if (response.body.error) {
          console.log(response.body.error);
          return next({
            type: types.OBTENER_LINEAS_ERROR,
            message: response.body.message
          });
        } else {
          return next({
            type: types.OBTENER_LINEAS_SUCCESS,
            lines: response.body.data
          });
        }
      });
      break;

    case types.GET_CATEGORIES_BY_LINE:
      request
        .get(URL_API + "/categorias")
        .query(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_CATEGORIES_BY_LINE_ERROR,
              error: err
            });
          }
          if (response.body.error) {
            return next({
              type: types.GET_CATEGORIES_BY_LINE_ERROR,
              error: response.body.error
            });
          } else {
            return next({
              type: types.GET_CATEGORIES_BY_LINE_SUCCESS,
              categories: response.body.data
            });
          }
        });
      break;  

    case types.OBTENER_CLIENTES:
      request.get(URL_API + "/admin/clientes/todos").end((err, response) => {
        if (err) {
          console.log(err);
          return next({
            type: types.OBTENER_CLIENTES_ERROR,
            error: err
          });
        }
        if (response.body.error) {
          console.log(response.body.error);
          return next({
            type: types.OBTENER_CLIENTES_ERROR,
            message: response.body.message
          });
        } else {
          return next({
            type: types.OBTENER_CLIENTES_SUCCESS,
            clients: response.body.data
          });
        }
      });
      break;

    case types.OBTENER_USUARIOS:
      request.get(URL_API + "/admin/users/todos").end((err, response) => {
        if (err) {
          console.log(err);
          return next({
            type: types.OBTENER_USUARIOS_ERROR,
            error: err
          });
        }
        if (response.body.error) {
          console.log(response.body.error);
          return next({
            type: types.OBTENER_USUARIOS_ERROR,
            message: response.body.message
          });
        } else {
          return next({
            type: types.OBTENER_USUARIOS_SUCCESS,
            users: response.body.data
          });
        }
      });
      break;

    case types.OBTENER_PEDIDOS:
      request
        .get(URL_API + "/admin/pedidos")
        .query(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.OBTENER_PEDIDOS_ERROR,
              error: "request error"
            });
          }
          if (response.body.error) {
            console.log(response.body.message);
            return next({
              type: types.OBTENER_PEDIDOS_ERROR,
              error: response.body.message
            });
          } else {
            return next({
              type: types.OBTENER_PEDIDOS_SUCCESS,
              orders: response.body.data
            });
          }
        });
      break;

    case types.OBTENER_PEDIDO_DETAIL:
      request
        .get(URL_API + "/admin/pedidos/detail")
        .query(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            action.response({ error: true, message: "request error" });
            return next({
              type: types.OBTENER_PEDIDO_DETAIL_ERROR
            });
          }
          if (response.body.error) {
            console.log(response.body.message);
            action.response(response.body);
            return next({
              type: types.OBTENER_PEDIDO_DETAIL_ERROR
            });
          } else {
            action.response(response.body);
            return next({
              type: types.OBTENER_PEDIDO_DETAIL_SUCCESS
            });
          }
        });
      break;

    case types.UPDATE_ORDER:
      request
        .post(URL_API + "/admin/pedidos/actualizar")
        .send(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            action.response("request error");
            return next({
              type: types.UPDATE_ORDER_ERROR
            });
          }
          if (response.body.error) {
            console.log(response.body.message);
            action.response(response.body.message);
            return next({
              type: types.UPDATE_ORDER_ERROR
            });
          } else {
            action.response(response.body.message);
            return next({
              type: types.UPDATE_ORDER_SUCCESS,
              data: action.data
            });
          }
        });
      break;

    case types.DASHBOARD:
      request.get(URL_API + "/admin/dashboard").end((err, response) => {
        if (err) {
          console.log(err);
          return next({
            type: types.DASHBOARD_ERROR,
            error: "request errror"
          });
        }
        if (response.body.error) {
          console.log(response.body.message);
          return next({
            type: types.DASHBOARD_ERROR,
            error: response.body.message
          });
        } else {
          return next({
            type: types.DASHBOARD_SUCCESS,
            data: response.body.data
          });
        }
      });
      break;

    case types.ORDERS_DASHBOARD:
      request
        .get(URL_API + "/admin/pedidos")
        .query(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            action.response({error: true, message: 'request error'})
            return next({
              type: types.ORDERS_DASHBOARD_ERROR,
            });
          }
          if (response.body.error) {
            console.log(response.body.message);
            action.response({error: true, message: 'error: response.body.message'})
            return next({
              type: types.ORDERS_DASHBOARD_ERROR,
            });
          } else {
            action.response({data: response.body.data})
            return next({
              type: types.ORDERS_DASHBOARD_SUCCESS,
            });
          }
        });
      break;

    case types.AGREGAR_PRODUCTO_BANNER:
      request
        .post(URL_API + "/admin/products/agregar/banner")
        .send(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.AGREGAR_PRODUCTO_BANNER_ERROR,
              message: "request error"
            });
          }
          if (response.body.error) {
            console.log(response.body.error);
            return next({
              type: types.AGREGAR_PRODUCTO_BANNER_ERROR,
              message: response.body.message
            });
          } else {
            return next({
              type: types.AGREGAR_PRODUCTO_BANNER_SUCCESS,
              message: response.body.message
            });
          }
        });
      break;

    case types.ACTUALIZAR_PRODUCTO_BANNER:
      request
        .post(URL_API + "/admin/products/actualizar/banner")
        .send(action.data)
        .end((err, response) => {
          if (err) {
            console.log(err);
            return next({
              type: types.ACTUALIZAR_PRODUCTO_BANNER_ERROR,
              message: "request error"
            });
          }
          if (response.body.error) {
            console.log(response.body.error);
            return next({
              type: types.ACTUALIZAR_PRODUCTO_BANNER_ERROR,
              message: response.body.message
            });
          } else {
            return next({
              type: types.ACTUALIZAR_PRODUCTO_BANNER_SUCCESS,
              message: response.body.message
            });
          }
        });
      break;

    case types.GET_PRODUCTS_BANNER:
      request
        .get(URL_API + "/admin/products/banner")
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.GET_PRODUCTS_BANNER_ERROR
            });
          } else {
            if (res.body.data) {
              return next({
                type: types.GET_PRODUCTS_BANNER_SUCCESS,
                products: res.body.data
              });
            } else {
              return next({
                type: types.GET_PRODUCTS_BANNER_ERROR
              });
            }
          }
        });
      break;

    case types.OBTENER_PRODUCTO_BANNER:
      request
        .post(URL_API + "/admin/products/banner/id")
        .send(action.data)
        .end((err, res) => {
          if (err) {
            console.log(err);
            return next({
              type: types.OBTENER_PRODUCTO_BANNER_ERROR
            });
          } else {
            if (res.body.data) {
              return next({
                type: types.OBTENER_PRODUCTO_BANNER_SUCCESS,
                product: res.body.data
              });
            } else {
              return next({
                type: types.OBTENER_PRODUCTO_BANNER_ERROR
              });
            }
          }
        });
      break;

    default:
      break;
  }
};

class Request {

    get(url, data) {
        const result = request
            .get(URL_API + url)
            .query(data)
            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }

    post(url, data) {
        const result = request
            .post(URL_API + url)
            .send(data)
            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }
}

export {Request};

export default apiService;
