import React, { Component } from "react";
import { connect } from "react-redux";

//Web Components
import * as action from "../../redux/actions";

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  buscar(event) {
    event.preventDefault();
    const busqueda = event.target.busqueda.value;
    const data = {
      busqueda
    };
    this.props.dispatch(action.findProducts(data));
    this.props.setpage(1);
  }

  render() {
    return (
      <div className="container-search-product">
        <form
          className="form-filter-products"
          onSubmit={this.buscar.bind(this)}
        >
          <input
            type="text"
            className="input input-text"
            placeholder="SKU, Nombre"
            name="busqueda"
          />
          <button className="btn btn-green">
            <i className="fas fa-search" />&nbsp; BUSCAR
          </button>
        </form>
        {/* 
                <div className="align-center">
                    <h5 className="auto color-black">
                        STOCK: &nbsp;
                    </h5>
                    <select name="stock-filter" className="input input-select">
                        <option value="1">
                            COMPLETO
                        </option>
                        <option value="3">
                            SIN EXISTENCIA
                        </option>
                    </select>
                </div>
                */}
      </div>
    );
  }
}

export default connect()(Filters);
