import React, { Component } from 'react';

class ModalBannerVP extends Component {
    closeModal() {
        document.getElementById("modal-banner").classList.add("hidden");
    }

    render() {
        let img = '', description = '', color = [];
        if (this.props.image) {
            img = this.props.image;
        }

        if (this.props.description) {
            description = this.props.description;
        }

        if (this.props.color) {
            color = this.props.color;
        }

        return (
            <div className="flex modal justify-center hidden" id="modal-banner">
                <div className="banner-content modal-content column">
                    <div className="modal-body-banner">
                        <div className="info-product column justify-center align-center" style={{ backgroundImage: `url(${img})` }}>
                            <div className="banner-b column container align-center justify-center">
                                <div className="text-banner justify-center">
                                    <h1 className="text-center" style={{color: `${color ? color : 'white'}`}}>
                                        {description ? description : ''}
                                    </h1>
                                </div>
                                <div className="justify-center">
                                    <button className="btn btn-green btn-banner">
                                        VER
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="close-banner justify-center">
                            <a onClick={this.closeModal.bind(this)}><i class="fas fa-times"></i></a>
                            {/*<i class="fas fa-times-circle"></i>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalBannerVP;