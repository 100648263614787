import React, { Component } from "react";
import IntlNumberInput from 'react-intl-number-input';
import { IMG_URL } from '../../api/urls';

class DetailProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      esp: this.props.esp,
      ing: this.props.ing,
      eliminando: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({
        esp: this.props.esp,
        ing: this.props.ing
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    if (
      document.getElementById("upload-product-image-input").files.length <= 0 &&
      this.props.productStatus === "agregar"
    ) {
      document.getElementById("product-image").style.border = "1px solid red";
      return;
    } else {
      document.getElementById("product-image").style.border = "none";
    }
    const id_linea = event.target.line.value;
    const id_categoria = event.target.category.value;
    if (id_linea <= 0) {
      document.getElementById("line").style.border = "2px solid red";
      return;
    }
    if (id_categoria <= 0) {
      document.getElementById("category").style.border = "2px solid red";
      return;
    }
    document.getElementById("line").style.border = "2px solid #E9EBED;";
    document.getElementById("category").style.border = "2px solid #E9EBED;";
    const precio = event.target.product_price.value.replace('.', '').replace(',', '.');
    const weight = event.target.product_weight.value.replace('.', '').replace(',', '.');
    const precio_promo = event.target.product_promotion.value.replace('.', '').replace(',', '.');
    const imagen = this.state.imagen;
    const data = {
      precio: parseFloat(precio),
      existencia: event.target.product_existence.checked,
      sku: event.target.product_sku.value,
      nombre: event.target.product_name.value,
      descripcion: event.target.product_description.value,
      nombre_ing: event.target.product_name_ing.value,
      descripcion_ing: event.target.product_description_ing.value,
      id_linea: id_linea,
      id_categoria: id_categoria,
      relevante: event.target.product_relevant.checked,
      peso: parseFloat(weight),
      precio_promo: parseFloat(precio_promo),
      a_consultar: event.target.a_consultar.checked,
      imagen: imagen
    };
    this.props.saveProduct(data);
  }

  eliminar(idProducto, event) {
    event.preventDefault();
    this.setState({ eliminando: true });

    this.props.deleteProducto(idProducto, this.eliminarResponse.bind(this));
  }

  eliminarResponse(res) {
    this.setState({ eliminando: false });
    if (res.eliminado) {
      window.location = "/productos";
    } else {
      alert(res.message);
    }
  }

  handleChange(event) {
    const src = event.target;
    let fr = new FileReader();
    fr.onload = e => {
      console.log(e);
      document.getElementById("product-image").src = e.target.result;
      this.setState({ imagen: e.target.result });
    };
    document.getElementById("product-image").style.border = "none";
    if (src.files.length > 0) {
      fr.readAsDataURL(src.files[0]);
    }
  }

  imageError(event) {
    event.target.src =
      "https://via.placeholder.com/400?text=Click+para.actualizar+imagen";
  }

  render() {
    let categories = [];
    let lines = [];
    if (this.props.categories) {
      categories = this.props.categories;
    }
    if (this.props.lines) {
      lines = this.props.lines;
    }
    let btn_loading;
    if (this.props.add_loadig) {
      btn_loading = <i className="fas fa-spinner fa-spin" />;
    }
    return (
      <div className="add-product">
        <div className="container container-add-product column">
          <div className="row">
            <div className="left column justify-center">
              <div
                className="product-image-preview responsive-img"
                onClick={() =>
                  document.getElementById("upload-product-image-input").click()
                }
              >
                {this.props.productStatus === "agregar" ? (
                  <img
                    src="https://via.placeholder.com/400?text=Click+para.subir+imagen"
                    alt="Click para cambiar imagen"
                    id="product-image"
                  />
                ) : (
                  <img
                    src={
                      IMG_URL + "/imagenes/obtener?id_imagen=" +
                      this.props.esp.idProducto
                    }
                    alt="Click para actualizar imagen"
                    id="product-image"
                    onError={this.imageError.bind(this)}
                  />
                )}
                <input
                  type="file"
                  id="upload-product-image-input"
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <div className="white-space-8" />
              <p className="text-center">Imagen del Producto</p>
            </div>
            <div className="right">
              <form
                className="column htmlForm-add-product"
                onSubmit={this.handleSubmit.bind(this)}
              >
                <div className="white-space-16" />
                <label htmlFor="product_name">Nombre del producto(ESP)</label>
                <div className="white-space-8" />
                <input
                  type="text"
                  name="product_name"
                  id="product_name"
                  className="input input-text"
                  value={this.state.esp.nombre || ''}
                  onChange={(event)=>{
                    const esp = this.state.esp;
                    Object.assign(esp, {nombre: event.target.value})
                    this.setState({esp: esp})
                  }}
                  required
                />
                <div className="white-space-16" />
                <label htmlFor="product_description">Descripción(ESP)</label>
                <div className="white-space-8" />
                <textarea
                  name="product_description"
                  id="product_description"
                  rows="5"
                  className="input textarea"
                  maxLength="800"
                  value={this.state.esp.descripcion || ''}
                  onChange={(event)=>{
                    const esp = this.state.esp;
                    Object.assign(esp, {descripcion: event.target.value})
                    this.setState({esp: esp})
                  }}
                />
                <div className="white-space-24" />
                <label htmlFor="product_name_ing">
                  Nombre del producto(ING)
                </label>
                <div className="white-space-8" />
                <input
                  type="text"
                  name="product_name_ing"
                  id="product_name_ing"
                  className="input input-text"
                  value={this.state.ing.nombre || ''}
                  required
                  onChange={(event)=>{
                    const ing = this.state.ing;
                    Object.assign(ing, {nombre: event.target.value})
                    this.setState({ing: ing})
                  }}
                />
                <div className="white-space-16" />
                <label htmlFor="product_description_ing">
                  Descripción(ING)
                </label>
                <div className="white-space-8" />
                <textarea
                  name="product_description_ing"
                  id="product_description_ing"
                  rows="5"
                  className="input textarea"
                  maxLength="800"
                  value={this.state.ing.descripcion || ''}
                  onChange={(event)=>{
                    const ing = this.state.ing;
                    Object.assign(ing, {descripcion: event.target.value})
                    this.setState({ing: ing})
                  }}
                />
                <div className="white-space-16" />
                <div className="justify-between">
                  <div className="column auto">
                    <label htmlFor="line">Línea</label>
                    <div className="white-space-8" />
                    <div className="price-div align-center">
                      <select
                        name="line"
                        id="line"
                        className="input input-select"
                        required
                        value={this.state.esp.idLinea}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {idLinea: event.target.value})
                          this.setState({esp: esp})
                        }}
                      >
                        <option value="0">{"<< Línea >>"}</option>
                        {lines.map((line, index) => (
                          <option key={index} value={line.idLinea}>
                            {line.Linea}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="column auto">
                    <label htmlFor="category">Categoría</label>
                    <div className="white-space-8" />
                    <div className="price-div align-center">
                      <select
                        name="category"
                        id="category"
                        className="input input-select"
                        required
                        value={this.state.esp.idCategoria}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {idCategoria: event.target.value})
                          this.setState({esp: esp})
                        }}
                      >
                        <option value="0">{"<< Categoría >>"}</option>
                        {categories.map((categorie, index) => (
                          <option key={index} value={categorie.idCategoria}>
                            {categorie.descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="column auto">
                    <label htmlFor="category">Visible</label>
                    <div className="white-space-8" />
                    <div className="align-center justify-center">
                      <input
                        type="checkbox"
                        name="product_existence"
                        id="product_existence"
                        className="input input-checkbox"
                        checked={this.state.esp.existencia? true : false}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {existencia: event.target.checked})
                          this.setState({esp: esp})
                        }}
                      />
                    </div>
                  </div>
                  <div className="column auto">
                    <label htmlFor="category">Relevante</label>
                    <div className="white-space-8" />
                    <div className="align-center justify-center">
                      <input
                        type="checkbox"
                        name="product_relevant"
                        id="product_relevant"
                        className="input input-checkbox"
                        checked={this.state.esp.relevante? true : false}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {relevante: event.target.checked})
                          this.setState({esp: esp})
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="justify-between">
                  <div className="column auto">
                  <div className="white-space-16"></div>
                    <label htmlFor="product-price">Precio</label>
                    <div className="white-space-8" />
                    <div className="price-div align-center">
                      &euro; &nbsp;{" "}
                      <IntlNumberInput
                        locale="es-ES"
                        precision={2}
                        name="product_price"
                        id="product_price"
                        className="input input-text"
                        required
                        value={this.state.esp.precio || 0}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {precio: event.target.value})
                          this.setState({esp: esp})
                        }}
                      />
                    </div>
                  </div>
                  <div className="column auto">
                    <div className="white-space-16"></div>
                    <label htmlFor="product_name">SKU</label>
                    <div className="white-space-8" />
                    <div className="price-div align-center">
                      # &nbsp;{" "}
                      <input
                        type="text"
                        name="product_sku"
                        id="product_sku"
                        className="input input-text"
                        required
                        value={this.state.esp.sku || ''}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {sku: event.target.value})
                          this.setState({esp: esp})
                        }}
                      />
                    </div>
                  </div>
                  <div className="column auto">
                    <div className="white-space-16"></div>
                    <label htmlFor="product_name">Peso</label>
                    <div className="white-space-8" />
                    <div className="price-div align-center">
                      Kg &nbsp;{" "}
                      <IntlNumberInput locale="es-ES" precision={3} name="product_weight" id="product_weight"
                        className="input input-text" required value={this.state.esp.peso || 0}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {peso: event.target.value})
                          this.setState({esp: esp})
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="white-space-8" />
                <div className="row">
                  <div className="column auto">
                    <div className="white-space-16"></div>
                    <label htmlFor="product-price">Promoción</label>
                    <div className="white-space-8" />
                    <div className="price-div align-center">
                      &euro; &nbsp;{" "}
                      <IntlNumberInput locale="es-ES" precision={2} name="product_promotion" id="product_promotion" className="input input-text" required
                        value={this.state.esp.promocion || 0}
                        onChange={(event) => {
                          const esp = this.state.esp;
                          Object.assign(esp, { promocion: event.target.value })
                          this.setState({ esp: esp })
                        }}
                      />
                    </div>
                  </div>
                  <div className="column auto">
                    <div className="white-space-16"></div>
                    <label htmlFor="product-price">A consultar</label>
                    <div className="white-space-8" />
                    <div className="align-center justify-center">
                      <input
                        type="checkbox"
                        name="a_consultar"
                        id="a_consultar"
                        className="input input-checkbox"
                        checked={this.state.esp.a_consultar? true : false}
                        onChange={(event)=>{
                          const esp = this.state.esp;
                          Object.assign(esp, {a_consultar: event.target.checked})
                          this.setState({esp: esp})
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="white-space-24" />
                <div className="btn-container-publish justify-between">
                  <button className="btn btn-aqua" type="submit">
                    <i className="fas fa-box-open" /> &nbsp; {this.props.productStatus === "agregar"
                      ? "PUBLICAR PRODUCTO "
                      : "ACTUALIZAR PRODUCTO"}
                    {btn_loading}
                  </button>
                  {this.props.productStatus === "modificar" ? (
                    <button className="btn btn-red" id="btn-delete-product"
                      onClick = {this.eliminar.bind(this, this.props.esp.idProducto )} >
                      <i className = {this.state.eliminando
                        ? "fas fa-spinner fa-spin"
                        : "fas fa-trash-alt" } />
                      &nbsp; ELIMINAR PRODUCTO
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="white-space-24" />
                <div>
                  <label>{this.props.add_message}</label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    /*function showImage(src, idDestino, i) {
        let fr = new FileReader();
        fr.onload = (this) => {
          document.getElementById(idDestino).src = this.result;
        }
        src.addEventListener("change", function() {
          document.getElementById(idDestino).style.border = "none";
          if (src.files.length > 0) {
            fr.readAsDataURL(src.files[i]);
          }
        });
      }*/
    //let src = document.getElementById("upload-product-image-input");
    //showImage(src, "product-image", 0);
  }
}

export default DetailProduct;
