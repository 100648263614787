import React from 'react';
import { Link } from 'react-router-dom';

const Aside = function() {

    return(
        <div className="aside column">
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-tachometer-alt"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/tablero">
                        TABLERO
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-box-open"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/pedidos">
                        PEDIDOS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-cubes"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/productos">
                        PRODUCTOS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-tag"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/promociones">
                        PROMOCIONES
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-th-large"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/categorias">
                        CATEGORIAS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-th-large"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/banner">
                        BANNER
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-book"></i>
                </div>
                <div className="menu-element-name align-center">
                    <a href="http://blog.elfosgourmet.eu/wp-admin">
                        BLOG
                    </a>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-comments"></i>
                </div>
                <div className="menu-element-name align-center">
                    <a href="https://developers.facebook.com/tools/comments/">
                        COMENTARIOS
                    </a>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-users"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/clientes">
                        CLIENTES
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-users-cog"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/usuarios">
                        USUARIOS
                    </Link>
                </div>
            </div>
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-file-excel"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/reportes">
                        REPORTES
                    </Link>
                </div>
            </div>
            <div className="menu-element">                
                <div className="menu-element-name align-center">
                    <Link to="/template">                        
                    </Link>
                </div>
            </div>
        </div>
    );

}

export default Aside;